import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useState } from "react";
import toast from "react-hot-toast";
import { HASURA_CLIENT_TOKEN_KEY } from "@/config";
import { useRefreshClientAccessTokenMutation } from "@/graphql/mutations/refreshClientAccessToken.graphql.types";
import useIsOTPClientPage from "@/hooks/client/useIsOTPClientPage";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import useIsOnlineStoreOTPPage from "@/hooks/onlineStore/useIsOnlineStoreOTPPage";
import { CLIENT_ACCESS_TOKEN_COOKIE_KEY } from "@/types";
import { setCookieWithHoursOfExpiration } from "@/utils";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export const HOURS_OF_INACTIVITY_BEFORE_EXPIRATION = 24;

type ClientAccessTokenContextType = {
  clientAccessToken?: string;
  medspaSlug?: string;
  getTokenContext: () => {
    context: { headers: { [HASURA_CLIENT_TOKEN_KEY]: string } };
  } | null;
  setClientAccessTokenCookie: (token: string) => void;
};

const ClientAccessTokenContext =
  createContext<ClientAccessTokenContextType | null>(null);

export const ClientAccessTokenProvider = ({ children }) => {
  const logError = useErrorLogger();
  const { query, pathname, push, asPath } = useRouter();

  const pagePath = usePagePath();
  const isClientPortalPage = useIsOTPClientPage();
  const isOnlineStorePage = useIsOnlineStoreOTPPage();

  const [refreshToken] = useRefreshClientAccessTokenMutation();

  const { medspaSlug } = query;
  const tokenFromCookie = Cookies.get(CLIENT_ACCESS_TOKEN_COOKIE_KEY);
  const [clientAccessToken, setClientAccessToken] = useState(tokenFromCookie);

  const setClientAccessTokenCookie = useCallback((token: string) => {
    setCookieWithHoursOfExpiration(
      CLIENT_ACCESS_TOKEN_COOKIE_KEY,
      token,
      HOURS_OF_INACTIVITY_BEFORE_EXPIRATION
    );
    setClientAccessToken(token);
  }, []);

  /**
   * Keep trying to get the client access token from the cookie
   * if it didn't get set on the first render
   */
  useEffectOnce(Boolean(!clientAccessToken && tokenFromCookie), () => {
    setClientAccessToken(tokenFromCookie);
  });

  const isSignInPage = pathname.includes("sign-in");

  const refreshClientAccessToken = async (token, medspaSlug) => {
    const { data } = await refreshToken({
      variables: {
        clientAccessToken: token as string,
        medspaSlug: medspaSlug as string,
      },
    });

    const returnedToken = data?.refreshClientAccessToken?.clientAccessToken;

    if (returnedToken) {
      setClientAccessTokenCookie(returnedToken);
      if (isSignInPage) {
        push(`/${pagePath}/${medspaSlug}`);
      }
    }
  };

  const redirectToSignIn = () => {
    if (!isSignInPage) {
      const redirectTo =
        pathname !== `/client/[medspaSlug]/appointments` ? asPath : "";

      const queryString = redirectTo
        ? `?redirectTo=${encodeURIComponent(redirectTo)}`
        : "";

      /**
       * Using this method instead of next/router.push to force a full page reload
       * so the provider/context can be re-initialized
       */
      if (pagePath && medspaSlug) {
        window.location.href = `/${pagePath}/${medspaSlug}/sign-in${queryString}`;
      } else {
        logError("Failed to redirect to OTP pages.");
      }
    }
  };

  const onOTPPageMount = () => {
    try {
      if (clientAccessToken && tokenFromCookie) {
        refreshClientAccessToken(clientAccessToken, medspaSlug);
      } else {
        if (
          !pathname.includes(
            "/online-store/[medspaSlug]/[tab]/[membershipId]/sign-up"
          )
        ) {
          redirectToSignIn();
        }
      }
    } catch (error) {
      toast.error(
        hasDjangoMutationError(error)
          ? error.message
          : "Failed to refresh client access token or redirect to OTP pages."
      );
      logError(error);
    }
  };

  /**
   * The onOTPPageMount function should be called only once when the app starts,
   * and only if the user is on an page with OTP as a requirement:
   * - Client Portal (/client/[medspaSlug]/*)
   * - Memberships Confirm (/online-store/[medspaSlug]/[tab]/[membershipId]/confirm)
   */
  const shouldUseClientAccessToken = Boolean(
    (isClientPortalPage || isOnlineStorePage) && medspaSlug
  );
  useEffectOnce(shouldUseClientAccessToken, onOTPPageMount);

  return (
    <ClientAccessTokenContext.Provider
      value={{
        clientAccessToken: shouldUseClientAccessToken
          ? (clientAccessToken as string)
          : null,
        medspaSlug: medspaSlug as string,
        getTokenContext: () => {
          return shouldUseClientAccessToken && clientAccessToken
            ? {
                context: {
                  headers: {
                    [HASURA_CLIENT_TOKEN_KEY]: clientAccessToken as string,
                  },
                },
              }
            : // It's important not to override the context with empty values, otherwise the auth headers are lost.
              // Returning a null won't override these settings.
              null;
        },
        setClientAccessTokenCookie,
      }}
    >
      {children}
    </ClientAccessTokenContext.Provider>
  );
};

const usePagePath = () => {
  const isClientPortalPage = useIsOTPClientPage();
  const isOnlineStorePage = useIsOnlineStoreOTPPage();

  let pagePath;

  if (isClientPortalPage) {
    pagePath = "client";
  }

  if (isOnlineStorePage) {
    pagePath = "packages";
  }

  return pagePath;
};

export const useClientAccessToken = (): ClientAccessTokenContextType => {
  return (
    useContext(ClientAccessTokenContext) || ({} as ClientAccessTokenContextType)
  );
};

export default ClientAccessTokenProvider;
