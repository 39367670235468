import { useCallback, useEffect, useRef } from "react";
import { useIsMoxieMobile } from "./useIsMoxieMobile";

export type MessageListener<T> = (payload: T) => void;
export type MessageListenerCallback = <T>(
  type: string,
  listener: MessageListener<T>
) => void;

/**
 * Hook used to listen for postMessage events from the mobile app.
 * Returns `addMessageListener` or `removeMessageListener` callbacks.
 *
 * If isMoxieMobile is equal to false this hook has no effect and calling
 * `addMessageListener` or `removeMessageListener` does nothing.
 *
 * Do not use this hook directly, both callbacks are should be used from `useMobileAppContext` instead.
 */
export const useMoxieMobileListener = () => {
  const { isMoxieMobile } = useIsMoxieMobile();
  // store listeners in a ref to avoid rerenders
  const messageListenersRef = useRef<Map<string, MessageListener<any>[]>>(
    new Map()
  );

  useEffect(() => {
    if (!isMoxieMobile) {
      return () => {};
    }

    const messagesHandler = (e: MessageEvent) => {
      try {
        if (!e.data) {
          return;
        }

        const eventData = JSON.parse(e.data);
        if (!eventData.type) {
          return;
        }

        for (const [type, listeners] of messageListenersRef.current.entries()) {
          if (type === eventData.type) {
            for (const messageListener of listeners) {
              messageListener(eventData.payload);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    window.addEventListener("message", messagesHandler);

    return () => {
      window.removeEventListener("message", messagesHandler);
    };
  }, [isMoxieMobile]);

  const addMessageListener = useCallback(
    <T>(type: string, listener: MessageListener<T>) => {
      if (!isMoxieMobile) {
        return;
      }

      const listeners = messageListenersRef.current.get(type);
      if (listeners) {
        listeners.push(listener);
      } else {
        messageListenersRef.current.set(type, [listener]);
      }
    },
    [isMoxieMobile]
  );

  const removeMessageListener = useCallback(
    <T>(type: string, listenerToRemove: MessageListener<T>) => {
      if (!isMoxieMobile) {
        return;
      }

      const listeners = messageListenersRef.current.get(type);
      if (listeners) {
        const newListeners = listeners.filter(
          (listener) => listener !== listenerToRemove
        );
        if (newListeners.length) {
          messageListenersRef.current.set(type, newListeners);
        } else {
          messageListenersRef.current.delete(type);
        }
      }
    },
    [isMoxieMobile]
  );

  return {
    addMessageListener,
    removeMessageListener,
  };
};
