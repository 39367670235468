import { ViewApi } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { format, subMinutes } from "date-fns";
import { debounce } from "lodash";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
  ReactNode,
  useLayoutEffect,
} from "react";
import {
  ChevronSmallDownIcon,
  ChevronSmallLeftIcon,
  ChevronSmallRightIcon,
} from "@/components/common/icons";
import { DATE_FORMATS } from "@/config";
import { VIOLET } from "@/config/mui/colorPalette";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";

const getCalendarHeaderLabel = (
  isDayView: boolean,
  currentDate: Date,
  dateInfo?: ViewApi
) => {
  if (!dateInfo) return "";

  if (isDayView) {
    return format(currentDate, DATE_FORMATS.CALENDAR);
  }

  const startDate = format(dateInfo.currentStart, DATE_FORMATS.SHORT_MONTH_DAY);
  const endDate = format(
    subMinutes(dateInfo.currentEnd, 1),
    DATE_FORMATS.SHORT_MONTH_DAY
  );
  return `${startDate} - ${endDate}`;
};

export type CalendarHeaderProps = {
  onNext: () => void;
  onPrev: () => void;
  onDateChange: (date: Date) => void;
  currentDate: Date;
  calendarRef: MutableRefObject<FullCalendar>;
  isDayView: boolean;
  saveCurrentDate?: (date: Date, endDate?: Date) => void;
  updateHeaderLabel?: boolean;
  setUpdateHeaderLabel?: Dispatch<SetStateAction<boolean>>;
  headerPrependLegend?: ReactNode;
  headerAppendLegend?: ReactNode;
  calendarContainerRef?: MutableRefObject<HTMLDivElement>;
};

const CalendarHeader = ({
  onNext,
  onPrev,
  onDateChange,
  currentDate,
  calendarRef,
  isDayView,
  saveCurrentDate,
  updateHeaderLabel,
  setUpdateHeaderLabel,
  headerAppendLegend,
  headerPrependLegend,
  calendarContainerRef,
}: CalendarHeaderProps) => {
  const theme = useTheme();
  const [isContainerMobile, setIsContainerMobile] = useState(false);

  useLayoutEffect(() => {
    const updateContainerMobile = () => {
      const containerWidth =
        calendarContainerRef?.current?.getBoundingClientRect().width;
      setIsContainerMobile(
        containerWidth
          ? containerWidth < theme.breakpoints.values["ipad-hor"]
          : false
      );
    };

    const debouncedUpdateContainerMobile = debounce(updateContainerMobile, 250);
    debouncedUpdateContainerMobile();
    window.addEventListener("resize", debouncedUpdateContainerMobile);

    return () =>
      window.removeEventListener("resize", debouncedUpdateContainerMobile);
  }, [calendarContainerRef, theme.breakpoints.values]);

  const isMobile = useMoxieMediaQuery("ipad-hor", "down") || isContainerMobile;
  const { getMedspaCurrentTime } = useMedspaTimezone();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [headerLabel, setHeaderLabel] = useState<string>(
    getCalendarHeaderLabel(
      isDayView,
      currentDate,
      calendarRef.current?.getApi().view
    )
  );

  // Update header label when date changes
  useEffect(() => {
    const dateInfo = calendarRef.current?.getApi().view;
    setHeaderLabel(getCalendarHeaderLabel(isDayView, currentDate, dateInfo));

    // For weekly and monthly views were we use prev and next methods, which don't re-render
    // the component, we need to update the header label manually
    if (setUpdateHeaderLabel) setUpdateHeaderLabel(false);
  }, [
    calendarRef,
    currentDate,
    isDayView,
    setUpdateHeaderLabel,
    updateHeaderLabel,
  ]);

  useEffect(() => {
    if (!updateHeaderLabel) return;
    // For weekly and monthly views we save the selected date in the calendar
    // this is the date saved to the session and used to initialize the day/week/month
    const selectedDate = calendarRef.current?.getApi().getDate();
    saveCurrentDate && saveCurrentDate(selectedDate);
  }, [calendarRef, saveCurrentDate, updateHeaderLabel]);

  return (
    <Box
      sx={{
        display: "flex",
        pb: 2,
        flexWrap: "wrap",
        position: "relative",
        ...(isMobile
          ? { justifyContent: "center", flexDirection: "column" }
          : { alignItems: "center" }),
      }}
    >
      {!isMobile && (
        <Button
          sx={{ position: "absolute", left: 0 }}
          variant="text"
          onClick={() => onDateChange(getMedspaCurrentTime())}
        >
          Go to today
        </Button>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 0,
          flexShrink: 1,
        }}
      >
        {headerPrependLegend}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 0,
          flexShrink: 1,
          justifyContent: "center",
        }}
      >
        <CalendarHeaderButton variant="prev" onClick={onPrev} />
        <Box
          sx={{
            px: 4,
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <Typography
            textAlign="center"
            variant="subtitleDefault"
            width="auto"
            mr={1}
          >
            {headerLabel}
          </Typography>
          <ChevronSmallDownIcon color="#4F0751" />
        </Box>
        <CalendarHeaderButton variant="next" onClick={onNext} />
        <SelectDateDialog
          isDialogOpen={dialogOpen}
          currentDate={currentDate}
          onDateChange={onDateChange}
          onClose={() => setDialogOpen(false)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 0,
          flexShrink: 1,
        }}
      >
        {headerAppendLegend}
      </Box>
    </Box>
  );
};

export default CalendarHeader;

const CalendarHeaderButton = ({
  variant,
  onClick,
}: {
  variant: "prev" | "next";
  onClick: () => void;
}) => {
  return (
    <IconButton
      onClick={onClick}
      size="small"
      aria-label={variant === "next" ? "Next day" : "Previous day"}
      sx={{
        bgcolor: "violet.light",
        "&:hover": {
          bgcolor: "violet.light",
        },
      }}
    >
      {variant === "next" ? (
        <ChevronSmallRightIcon color={VIOLET[100]} />
      ) : (
        <ChevronSmallLeftIcon color={VIOLET[100]} />
      )}
    </IconButton>
  );
};

function SelectDateDialog({
  isDialogOpen,
  currentDate,
  onDateChange,
  onClose,
}: {
  isDialogOpen: boolean;
  currentDate: Date;
  onDateChange: (date: Date) => void;
  onClose: () => void;
}) {
  return (
    <Dialog open={isDialogOpen} disableEscapeKeyDown>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          slotProps={{
            actionBar: {
              actions: ["accept"],
            },
          }}
          onChange={onDateChange}
          value={currentDate}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClose={onClose}
        />
      </LocalizationProvider>
    </Dialog>
  );
}
