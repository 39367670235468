import { useCallback, useMemo } from "react";
import { useUser } from "@/auth/useUser";
import { useMedspaProvidersQuery } from "@/graphql/queries/medspaProviders.graphql.types";

export default function useProviderIndex() {
  const { medspa } = useUser();

  const { data: providersData } = useMedspaProvidersQuery({
    variables: {
      id: medspa,
    },
    skip: !medspa,
  });
  const providers = useMemo(
    () => providersData?.medspaByPk?.userMedspas || [],
    [providersData]
  );

  return useCallback(
    (providerUserId: string) => {
      const index = providers.findIndex(
        (provider) => provider.user.id === providerUserId
      );

      return index === -1 ? undefined : index;
    },
    [providers]
  );
}
