import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { ReactNode, useEffect, useState } from "react";
import { CrossIcon } from "@/components/common/icons";
import SlideUpTransition from "@/components/common/modals/slideUpTransition";
import { BLACK } from "@/config/mui/colorPalette";

export const useEndTransitionForNullComponent = (open: boolean) => {
  /* If your component returns null or SlideBottomDialog,
   and you want to persist the transition,
   please use this hook to handle the "open" state
  */

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return { isOpen, setIsOpen };
};

export type SlideBottomDialogBaseProps = Omit<
  DialogProps,
  "onClose" | "onChange"
> & {
  isDisabled?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

type SlideBottomDialogProps = SlideBottomDialogBaseProps & {
  title: string;
  children: ReactNode;
  headerLeftButton?: ReactNode;
  submitButtonText?: string;
  withCancelButton?: boolean;
  withActions?: boolean;
  crossIconColor?: string;
  submitButtonProps?: ButtonProps;
};

function SlideBottomDialog({
  open,
  title,
  PaperProps,
  headerLeftButton,
  children,
  submitButtonText = "Save",
  isDisabled,
  onClose,
  onSubmit,
  withCancelButton,
  withActions = true,
  crossIconColor = BLACK,
  submitButtonProps,
  ...props
}: SlideBottomDialogProps) {
  return (
    <Dialog
      open={open}
      TransitionComponent={SlideUpTransition}
      sx={{
        "& .MuiDialog-scrollPaper": {
          alignItems: "flex-end",
          maxWidth: "640px",
          margin: "0 auto",
        },
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "100%",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          margin: 0,
          mt: 4,
          ...PaperProps?.sx,
        },
      }}
      onClose={onClose}
      {...props}
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          gap: 2,
          "& > *": { flex: "1 1 0" },
        }}
      >
        <Box>{headerLeftButton}</Box>

        <Box sx={{ flexGrow: "2" }}>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton onClick={onClose}>
            <CrossIcon size={24} color={crossIconColor} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 2 }}>{children}</DialogContent>

      {withActions && (
        <DialogActions sx={{ px: 2 }}>
          {withCancelButton && (
            <Button fullWidth size="large" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            fullWidth
            size="large"
            variant="contained"
            disabled={isDisabled}
            onClick={onSubmit}
            {...submitButtonProps}
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default SlideBottomDialog;
