import { isValid, set } from "date-fns";
import React from "react";
import DatePicker, {
  type DatePickerProps,
} from "@/components/common/datetimePicker/DatePicker";

type Props = Omit<DatePickerProps, "onAccept" | "value"> & {
  fullWidth?: boolean;
  value: Date;
  onChange: (date: Date) => void;
};

export default function TimeSelectorDatePicker({
  fullWidth = false,
  value,
  onChange,
  ...props
}: Props) {
  return (
    <DatePicker
      {...props}
      value={value}
      slotProps={{
        textField: {
          fullWidth: fullWidth,
        },
      }}
      onAccept={(val) => {
        const date = val as Date;

        if (isValid(date)) {
          const newDate = set(new Date(value), {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          });
          onChange(newDate);
        }
      }}
    />
  );
}
