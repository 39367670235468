import { useUser } from "@/auth/useUser";
import { useNoShowVisitClientsQuery } from "@/graphql/queries/clients.graphql.types";
import { CLIENTS_PER_PAGE } from "./useClients";

export default function useNoShowVisitClients(
  enabled = false,
  withPagination = false
) {
  const { medspa } = useUser();

  const baseVariables = {
    medspaId: medspa,
  };

  const queryVariables = withPagination
    ? { ...baseVariables, limit: CLIENTS_PER_PAGE }
    : baseVariables;

  const { data, ...rest } = useNoShowVisitClientsQuery({
    variables: queryVariables,
    skip: !medspa || !enabled,
    fetchPolicy: "cache-and-network",
  });

  if (!enabled) return { data: undefined } as { data: undefined }; // otherwise ts marks data as 'any'

  return { data, ...rest };
}
