export function formatCurrency({
  amount,
  currency = "USD",
  showCents = true,
}: {
  amount: number;
  currency?: string;
  showCents?: boolean;
}) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    ...(!showCents && { maximumFractionDigits: 0 }),
  }).format(amount);
}

export function pixelsToNumber(pixels: string) {
  return pixels.replace("px", "");
}
