import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SetGfeReviewLastStartedMutationVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type SetGfeReviewLastStartedMutation = {
  __typename?: "mutation_root";
  updateGfeReviewSubmissionByPk?: {
    __typename?: "GfeReviewSubmission";
    id: string;
  } | null;
};

export type CreateGfeReviewSubmissionMutationVariables = Types.Exact<{
  reviewedById: Types.Scalars["bigint"]["input"];
  gfeRequestId: Types.Scalars["bigint"]["input"];
}>;

export type CreateGfeReviewSubmissionMutation = {
  __typename?: "mutation_root";
  insertGfeReviewSubmissionOne?: {
    __typename?: "GfeReviewSubmission";
    id: string;
  } | null;
};

export const SetGfeReviewLastStartedDocument = gql`
  mutation SetGfeReviewLastStarted($id: bigint!) {
    updateGfeReviewSubmissionByPk(
      pkColumns: { id: $id }
      _set: { reviewLastStartedAt: "now()" }
    ) {
      id
    }
  }
`;
export type SetGfeReviewLastStartedMutationFn = Apollo.MutationFunction<
  SetGfeReviewLastStartedMutation,
  SetGfeReviewLastStartedMutationVariables
>;

/**
 * __useSetGfeReviewLastStartedMutation__
 *
 * To run a mutation, you first call `useSetGfeReviewLastStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGfeReviewLastStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGfeReviewLastStartedMutation, { data, loading, error }] = useSetGfeReviewLastStartedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetGfeReviewLastStartedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGfeReviewLastStartedMutation,
    SetGfeReviewLastStartedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetGfeReviewLastStartedMutation,
    SetGfeReviewLastStartedMutationVariables
  >(SetGfeReviewLastStartedDocument, options);
}
export type SetGfeReviewLastStartedMutationHookResult = ReturnType<
  typeof useSetGfeReviewLastStartedMutation
>;
export type SetGfeReviewLastStartedMutationResult =
  Apollo.MutationResult<SetGfeReviewLastStartedMutation>;
export type SetGfeReviewLastStartedMutationOptions = Apollo.BaseMutationOptions<
  SetGfeReviewLastStartedMutation,
  SetGfeReviewLastStartedMutationVariables
>;
export const CreateGfeReviewSubmissionDocument = gql`
  mutation CreateGfeReviewSubmission(
    $reviewedById: bigint!
    $gfeRequestId: bigint!
  ) {
    insertGfeReviewSubmissionOne(
      object: {
        reviewedById: $reviewedById
        gfeReviewRequestId: $gfeRequestId
        reviewType: "async"
      }
    ) {
      id
    }
  }
`;
export type CreateGfeReviewSubmissionMutationFn = Apollo.MutationFunction<
  CreateGfeReviewSubmissionMutation,
  CreateGfeReviewSubmissionMutationVariables
>;

/**
 * __useCreateGfeReviewSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateGfeReviewSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGfeReviewSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGfeReviewSubmissionMutation, { data, loading, error }] = useCreateGfeReviewSubmissionMutation({
 *   variables: {
 *      reviewedById: // value for 'reviewedById'
 *      gfeRequestId: // value for 'gfeRequestId'
 *   },
 * });
 */
export function useCreateGfeReviewSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGfeReviewSubmissionMutation,
    CreateGfeReviewSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGfeReviewSubmissionMutation,
    CreateGfeReviewSubmissionMutationVariables
  >(CreateGfeReviewSubmissionDocument, options);
}
export type CreateGfeReviewSubmissionMutationHookResult = ReturnType<
  typeof useCreateGfeReviewSubmissionMutation
>;
export type CreateGfeReviewSubmissionMutationResult =
  Apollo.MutationResult<CreateGfeReviewSubmissionMutation>;
export type CreateGfeReviewSubmissionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateGfeReviewSubmissionMutation,
    CreateGfeReviewSubmissionMutationVariables
  >;
