import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import React, { forwardRef } from "react";

type Props = {
  color: string;
  size: string;
  icon: ReactNode;
  sx?: SxProps<Theme>;
};

const IconCircleContainer = forwardRef<HTMLDivElement, Props>(
  ({ color, size, icon, sx, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={{
          borderRadius: "50%",
          minWidth: size,
          minHeight: size,
          width: size,
          height: size,
          bgcolor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...sx,
        }}
      >
        {icon}
      </Box>
    );
  }
);

IconCircleContainer.displayName = "IconCircleContainer";

export default IconCircleContainer;
