import { useMemo } from "react";
import { useUser } from "@/auth/useUser";
import { useVisitsAndAvailabilityEventsWithDateRangeQuery } from "@/graphql/queries/visit/visitsAndAvailabilityEventsWithDateRange.graphql.types";
import { ACTIVE_VISIT_STATUSES } from "@/types";
import useAvailabilityEvents from "./useAvailabilityEvents";
import { useDateToMedspaWeekRange } from "./useDateToMedspaWeekRange";
import useVisitEvents from "./useVisitEvents";

export default function useMDMeetingsCalendarEvents(
  start?: Date,
  providerIds?: string[]
) {
  const { medspa } = useUser();
  const dateRange = useDateToMedspaWeekRange(start ?? new Date());

  const { data } = useVisitsAndAvailabilityEventsWithDateRangeQuery({
    variables: {
      medspaId: medspa,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      status: ACTIVE_VISIT_STATUSES,
      providers: providerIds || [],
      gfeStatusesExp: {},
      serviceDevicesExp: {},
    },
    skip: !start || !providerIds || !medspa || !dateRange,
    fetchPolicy: "cache-and-network",
  });

  const visitEvents = useVisitEvents(data?.visit);

  const { timeBlockEvents, specialShiftsEvents } = useAvailabilityEvents(
    data?.availabilityEvent
  );

  const calendarEvents = useMemo(() => {
    const plannedVisits =
      visitEvents.map(({ extendedProps, ...visit }) => {
        return {
          ...visit,
          end: visit.end,
          extendedProps: {
            ...extendedProps,
            showDropdown: false,
            clickable: false,
          },
        };
      }) || [];

    const timeBlockers = timeBlockEvents.map(({ extendedProps, ...event }) => ({
      ...event,
      extendedProps: {
        ...extendedProps,
        showDropdown: false,
        clickable: false,
      },
    }));

    const specialShifts = specialShiftsEvents.map(
      ({ extendedProps, ...event }) => ({
        ...event,
        extendedProps: {
          ...extendedProps,
          showDropdown: false,
        },
      })
    );

    return [...plannedVisits, ...timeBlockers, ...specialShifts];
  }, [visitEvents, specialShiftsEvents, timeBlockEvents]);

  return calendarEvents;
}
