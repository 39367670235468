import { useMemo } from "react";
import { DatePeriods } from "@/components/common/filters/dateFilter/dateDialog";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import useApptHistoryTableData from "@/hooks/complianceHub/useApptHistoryTableData";
import useProtocolsAndStandingOrdersTableData from "@/hooks/complianceHub/useProtocolsAndStandingOrdersTableData";
import useServiceDocumentsTableData from "@/hooks/complianceHub/useServiceDocumentsTableData";
import { useIsProviderOwner } from "@/hooks/user/useIsProviderOwner";
import { getTimeRange } from "@/store/views/serviceFlow/reviewVisits/filters/dateFilterStore";

// Make sure issues from all compliance hub tables/views are summed up here
// When a new section's issue count is included here it should be put behind a corresponding feature flag

export default function useComplianceHubIssueCount() {
  const isProviderOwner = useIsProviderOwner();
  const {
    complianceHubV2Enabled,
    complianceHubV3Enabled,
    complianceHubApptHistoryEnabled,
  } = useFeatureFlags();

  const { startDate, endDate } = useMemo(() => {
    return getTimeRange(DatePeriods.LAST_30_DAYS);
  }, []);

  let issuesSum = 0;
  const { issues: serviceDocumentsIssueCount } = useServiceDocumentsTableData({
    skip: !isProviderOwner,
  });
  const { issues: protocolsAndStandingOrdersIssues } =
    useProtocolsAndStandingOrdersTableData({ skip: !isProviderOwner });

  const { issues: appointmentHistoryIssues } = useApptHistoryTableData({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    skip: !complianceHubApptHistoryEnabled,
  });

  if (complianceHubV3Enabled) issuesSum += serviceDocumentsIssueCount;
  if (complianceHubV2Enabled) issuesSum += protocolsAndStandingOrdersIssues;
  if (complianceHubApptHistoryEnabled) issuesSum += appointmentHistoryIssues;

  return issuesSum;
}
