import { useEffect, useState } from "react";
import { OrderBy } from "@/__generated__/schema.graphql.types";
import {
  ClientsQuery,
  ClientsQueryResult,
  NoShowVisitClientsQueryResult,
  RecentVisitClientsQueryResult,
  useClientsQuery,
} from "@/graphql/queries/clients.graphql.types";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import { prepareSearchStringForQuery } from "@/utils/pagination";
import useAction from "../misc/useAction";
import {
  NO_SHOW_VISITS_SORT_KEY,
  RECENT_VISITS_SORT_KEY,
  Sort,
} from "./useClientsSorting";
import useNoShowVisitClients from "./useNoShowVisitClients";
import useRecentVisitClients from "./useRecentVisitClients";

type QueryRestType = Omit<
  | ClientsQueryResult
  | RecentVisitClientsQueryResult
  | NoShowVisitClientsQueryResult,
  "data"
>;

export const CLIENTS_PER_PAGE = 25;

export const useClients = (
  searchQuery: string,
  sortBy: Sort[],
  withPagination?: boolean
) => {
  const medspa = useMedspaId();
  const isRecentVisitsView = sortBy.some((el) => RECENT_VISITS_SORT_KEY in el);
  const isNoShowVisitsView = sortBy.some((el) => NO_SHOW_VISITS_SORT_KEY in el);
  const isCustomView = isRecentVisitsView || isNoShowVisitsView;

  const [isFetchingDone, setIsFetchingDone] = useState<boolean>(false);

  useEffect(() => {
    setIsFetchingDone(false);
  }, [searchQuery, sortBy]);

  const baseVariables = {
    medspaId: medspa,
    orderBy: [...sortBy, { id: OrderBy.Asc }], // to make sure we have consistent order
    searchString: prepareSearchStringForQuery(searchQuery),
  };

  const queryVariables = withPagination
    ? { ...baseVariables, limit: CLIENTS_PER_PAGE }
    : baseVariables;

  const { data: clientsData, ...clientsRest } = useClientsQuery({
    variables: queryVariables,
    skip: isCustomView || !medspa,
    fetchPolicy: "network-only",
  });

  const { data: recentVisitsData, ...recentVisitsRest } = useRecentVisitClients(
    isRecentVisitsView,
    withPagination
  );
  const { data: noShowVisitsData, ...noShowVisitsRest } = useNoShowVisitClients(
    isNoShowVisitsView,
    withPagination
  );

  const data: ClientsQuery =
    noShowVisitsData || recentVisitsData || clientsData; // make sure custom view data is before clientsData

  const clients = data?.client;

  const getQueryRest = (): QueryRestType => {
    if (isRecentVisitsView)
      return recentVisitsRest as RecentVisitClientsQueryResult;
    if (isNoShowVisitsView)
      return noShowVisitsRest as NoShowVisitClientsQueryResult;

    return clientsRest;
  };

  const queryRest = getQueryRest();

  const { fetchMore } = queryRest;

  const handleFetchNextPage = async () => {
    await fetchMore({
      variables: {
        offset: clients.length,
      },
      updateQuery: (prev: ClientsQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (fetchMoreResult.client.length < CLIENTS_PER_PAGE) {
          setIsFetchingDone(true);
        }

        return Object.assign({}, prev, {
          client: [...prev.client, ...fetchMoreResult.client],
        });
      },
    });
  };

  const { handleAction: fetchNextPage, isLoading } =
    useAction(handleFetchNextPage);

  const loading = !data || isLoading;

  const hasNextPage = clients?.length >= CLIENTS_PER_PAGE && !isFetchingDone;

  return {
    clients,
    loading,
    pagination: {
      hasNextPage,
      fetchNextPage,
    },
  };
};
