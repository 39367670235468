import React, { createContext, useContext, useState, ReactNode } from "react";
import CreateMdMeetingDrawer from "@/components/complianceHub/createMdMeetingDrawer";
import { MdMeetingsByMedspaQuery } from "@/graphql/queries/mdMeetings/mdMeetingsByMedspa.graphql.types";

type Meeting = MdMeetingsByMedspaQuery["mdMeeting"][0];

interface CreateMdMeetingDrawerContextType {
  isOpen: boolean;
  currentMeeting: Meeting | null;
  drawerTitle: string;
  openDrawer: (params: { meeting?: Meeting; title: string }) => void;
  closeDrawer: () => void;
}

const CreateMdMeetingDrawerContext = createContext<
  CreateMdMeetingDrawerContextType | undefined
>(undefined);

export const useCreateMdMeetingDrawer = () => {
  const context = useContext(CreateMdMeetingDrawerContext);
  if (!context) {
    throw new Error(
      "useCreateMdMeetingDrawer must be used within a CreateMdMeetingDrawerProvider"
    );
  }
  return context;
};

interface CreateMdMeetingDrawerProviderProps {
  children: ReactNode;
}

export const CreateMdMeetingDrawerProvider = ({
  children,
}: CreateMdMeetingDrawerProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMeeting, setCurrentMeeting] = useState<Meeting | null>(null);
  const [drawerTitle, setDrawerTitle] = useState<string>("");

  const openDrawer = ({
    meeting,
    title,
  }: {
    meeting?: Meeting;
    title: string;
  }) => {
    setCurrentMeeting(meeting || null);
    setDrawerTitle(title);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setCurrentMeeting(null);
    setDrawerTitle("");
  };

  return (
    <CreateMdMeetingDrawerContext.Provider
      value={{ isOpen, currentMeeting, drawerTitle, openDrawer, closeDrawer }}
    >
      {children}
      <CreateMdMeetingDrawer />
    </CreateMdMeetingDrawerContext.Provider>
  );
};
