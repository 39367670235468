import { differenceInHours, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { ReviewStatus } from "@/types";

export const getVisitRangeDates = (
  startTime: string,
  endTime: string,
  timezone: string
): string => {
  const dateString = formatInTimeZone(
    startTime,
    timezone,
    DATE_FORMATS.DATE_PICKER
  );
  const timeStartString = formatInTimeZone(
    startTime,
    timezone,
    TIME_FORMATS.TILE
  );
  const timeEndString = formatInTimeZone(endTime, timezone, TIME_FORMATS.TILE);
  return `${dateString} • ${timeStartString} - ${timeEndString}`;
};

export const getLastChartLogDate = (
  timeString?: string,
  timezone?: string
): string => {
  if (!timeString || !timezone) {
    return "";
  }

  const dateString = formatInTimeZone(
    timeString,
    timezone,
    DATE_FORMATS.DATE_PICKER
  );
  const timeStartString = formatInTimeZone(
    timeString,
    timezone,
    TIME_FORMATS.TILE
  );

  return `${dateString} • ${timeStartString}`;
};

export const getChartLog = (chartStatus: ReviewStatus, date: string) => {
  if (!date) return "";

  switch (chartStatus) {
    case ReviewStatus.COMPLETE:
      return `Chart completed ${date}`;
    case ReviewStatus.REVIEWED:
      return `Chart reviewed ${date}`;
    case ReviewStatus.NOT_REQUIRED:
      return `Chart marked as not required ${date}`;
    default:
      return "";
  }
};

export const calculateOverdueTime = (overdueDate: string) => {
  const hoursOverdue = differenceInHours(new Date(), parseISO(overdueDate));

  if (hoursOverdue < 1) {
    return "less than one hour";
  }
  if (hoursOverdue < 24) {
    return `${hoursOverdue} hour${hoursOverdue > 1 ? "s" : ""}`;
  }

  const daysOverdue = Math.floor(hoursOverdue / 24);
  return `${daysOverdue} day${daysOverdue > 1 ? "s" : ""}`;
};
