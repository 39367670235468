import { useUser } from "@/auth/useUser";

export const useIsProviderOwner = () => {
  const { userMedspa } = useUser();

  return userMedspa?.isProviderOwner;
};

export const useCanConfigureMedspa = () => {
  return useIsProviderOwner();
};
