import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useUser } from "@/auth/useUser";
import {
  DrawerHeader,
  DrawerContent,
  Drawer,
  DrawerActions,
} from "@/components/common/drawer";
import { ChevronSmallLeftIcon } from "@/components/common/icons";
import TimeSelector, {
  TimeSelectorRef,
} from "@/components/complianceHub/timeSelector/timeSelector";
import TimeSelectorButton from "@/components/complianceHub/timeSelector/timeSelectorButton";
import { useMedspaMedicalDirectors } from "@/hooks/common/useMedspaMedicalDirectors";
import { useMedspaProviders } from "@/hooks/common/useMedspaProviders";
import { useCreateMdMeetingForm } from "@/hooks/complianceHub/useCreateMdMeetingForm";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import useMDMeetingsCalendarEvents from "@/hooks/visits/useMDMeetingsCalendarEvents";
import { MEDICAL_DIRECTOR } from "@/types";
import ComplianceAutocompleteField from "./createMdMeetingForm/complianceAutocompleteFields";
import { FormActions } from "./createMdMeetingForm/formActions";

export default function CreateMdMeetingDrawer() {
  const {
    // Drawer state
    drawerTitle,
    isOpen,
    isEditing,
    closeDrawer,

    // Form control and validation
    control,
    errors,
    isCreateButtonEnabled,
    onSubmit,
    setValue,

    // Loading state
    isLoading,

    // Form values
    startDate,
    endDate,
    providers,
  } = useCreateMdMeetingForm();

  const [isTimeSelectorOpen, setIsTimeSelectorOpen] = useState(false);

  const timeSelectorRef = useRef<TimeSelectorRef>(null);
  const handleCancelTimeSelector = () => timeSelectorRef.current?.cancel();
  const handleSaveTimeSelector = () => timeSelectorRef.current?.confirm();

  const calendarEvents = useMDMeetingsCalendarEvents(
    startDate,
    providers?.map(({ userId }) => userId)
  );

  return (
    <Drawer open={isOpen} onClose={closeDrawer}>
      <DrawerHeader
        title={drawerTitle}
        leadingAction={
          isTimeSelectorOpen ? (
            <IconButton onClick={handleCancelTimeSelector}>
              <ChevronSmallLeftIcon />
            </IconButton>
          ) : undefined
        }
      />
      <DrawerContent>
        <Stack
          spacing={5}
          sx={{
            width: {
              xs: "100%",
              md: "375px",
            },
          }}
        >
          {isTimeSelectorOpen ? (
            <TimeSelector
              ref={timeSelectorRef}
              calendarEvents={calendarEvents}
              closeSelector={() => setIsTimeSelectorOpen(false)}
              startDate={startDate}
              endDate={endDate}
              onSave={(startDate, endDate) => {
                setValue("startDate", startDate);
                setValue("endDate", endDate);
              }}
            />
          ) : (
            <>
              <Stack spacing={2.5}>
                <ProvidersAutocompleteField control={control} errors={errors} />
                <MdsAutocompleteField control={control} errors={errors} />
                <SelectDateAndTimeField
                  startDate={startDate}
                  endDate={endDate}
                  onClick={() => setIsTimeSelectorOpen(true)}
                />
                <MeetingTypeField control={control} />
                <MeetingDetailsField control={control} errors={errors} />
              </Stack>
              <FormActions
                submitButtonText={isEditing ? "Save changes" : "Create meeting"}
                onCancel={closeDrawer}
                onSubmit={onSubmit}
                isCreateButtonEnabled={isCreateButtonEnabled}
                isLoading={isLoading}
              />
            </>
          )}
        </Stack>
      </DrawerContent>
      <DrawerActions>
        {isTimeSelectorOpen && (
          <>
            <Button
              onClick={handleCancelTimeSelector}
              variant="outlined"
              sx={{ flex: 1, height: 40 }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveTimeSelector}
              variant="contained"
              sx={{ flex: 1, height: 40 }}
              size="small"
            >
              Save
            </Button>
          </>
        )}
      </DrawerActions>
    </Drawer>
  );
}

function ProvidersAutocompleteField({ control, errors }) {
  const { medspaProviders } = useMedspaProviders();

  const providerOptions = useMemo(() => {
    return medspaProviders?.map((provider) => ({
      id: provider.id,
      userId: provider.user.id,
      label: provider.user.fullName,
    }));
  }, [medspaProviders]);

  return (
    <ComplianceAutocompleteField
      control={control}
      errors={errors}
      name="providers"
      label="Select Providers"
      options={providerOptions}
      placeholder="Search for providers..."
      required
    />
  );
}

function MdsAutocompleteField({ control, errors }) {
  const { userMedspa } = useUser();
  const { medspaMedicalDirectors } = useMedspaMedicalDirectors();

  const isMd = useUserHasRoles([MEDICAL_DIRECTOR]);

  const mdOptions = useMemo(() => {
    if (isMd && userMedspa) {
      const currentMd = medspaMedicalDirectors?.find(
        (md) => md.id === userMedspa.id
      );
      if (currentMd) {
        return [
          {
            id: currentMd.id,
            label: currentMd.user.fullName,
            userId: currentMd.user.id,
          },
        ];
      }
    }
    return medspaMedicalDirectors?.map((md) => ({
      id: md.id,
      label: md.user.fullName,
      userId: md.user.id,
    }));
  }, [medspaMedicalDirectors, isMd, userMedspa]);

  return (
    <ComplianceAutocompleteField
      control={control}
      errors={errors}
      name="mds"
      label="Select MDs"
      options={mdOptions}
      placeholder="Search for MDs..."
      required
    />
  );
}

function SelectDateAndTimeField({ onClick, startDate, endDate }) {
  return (
    <Box>
      <Typography variant="labelTiny" color="text.secondary" gutterBottom>
        Select date & time{" "}
        <Typography color="error" component="span">
          *
        </Typography>
      </Typography>
      <TimeSelectorButton
        onClick={onClick}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
}

function MeetingTypeField({ control }) {
  return (
    <Controller
      name="format"
      control={control}
      render={({ field }) => (
        <>
          <Typography variant="labelTiny" color="text.secondary">
            Meeting type{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
          <RadioGroup {...field} row>
            <FormControlLabel
              value="video_or_phone"
              control={<Radio />}
              label="Video or phone"
            />
            <FormControlLabel
              value="in_person"
              control={<Radio />}
              label="In person"
            />
          </RadioGroup>
        </>
      )}
    />
  );
}

function MeetingDetailsField({ control, errors }) {
  return (
    <Controller
      name="meetingDetails"
      control={control}
      render={({ field }) => (
        <>
          <Typography variant="labelTiny" color="text.secondary" gutterBottom>
            Meeting details
          </Typography>
          <TextField
            {...field}
            fullWidth
            size="small"
            placeholder="Add some details here i.e. “I will call you”"
            error={!!errors.meetingDetails}
            helperText={errors.meetingDetails?.message}
          />
        </>
      )}
    />
  );
}
