import { useRouter } from "next/router";
import { ComponentType } from "react";
import toast from "react-hot-toast";
import {
  ClockIcon,
  Icon,
  MinusSmallIcon,
  PinpaperFilledIcon,
} from "@/components/common/icons";
import BulletListIconSvg from "@/components/common/icons/bulletListIconSvg";
import ActionIconContainer from "@/components/common/icons/containers/actionIconContainer";
import {
  GREY,
  SUCCESS_PRIMARY,
  SUCCESS,
  WARNING_PRIMARY,
  WARNING,
} from "@/config/mui/colorPalette";
import {
  useMarkDocumentAsRequiredMutation,
  useMarkDocumentAsNotRequiredMutation,
} from "@/graphql/mutations/complianceHub/markDocumentsAsRequired.graphql.types";
import { useProtocolsAndStandingOrdersTableQuery } from "@/graphql/queries/complianceHub/protocolsAndStandingOrdersTable.graphql.types";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import { PdfFileSource } from "@/types";

export enum DocumentType {
  SERVICE_PROTOCOL = "service_protocol",
  STANDING_ORDER = "standing_order",
}

export enum ServiceDocumentStatus {
  NOT_REQUIRED = "not_required",
  COMPLETED = "completed",
  IN_PROGRESS = "in_progress",
  NOT_REQUESTED = "not_requested",
}

const SERVICE_DOCUMENT_STATUS_ICON: Record<
  ServiceDocumentStatus,
  (
    documentType: DocumentType
  ) =>
    | ComponentType<Icon>
    | (({ size, color }: { size: string; color: string }) => JSX.Element)
> = {
  [ServiceDocumentStatus.NOT_REQUIRED]: () => MinusSmallIcon,
  [ServiceDocumentStatus.COMPLETED]: (documentType) =>
    getDocumentTypeIcon(documentType),
  [ServiceDocumentStatus.IN_PROGRESS]: () => ClockIcon,
  [ServiceDocumentStatus.NOT_REQUESTED]: (documentType) =>
    getDocumentTypeIcon(documentType),
};

const PRIMARY_COLOR: Record<ServiceDocumentStatus, string> = {
  [ServiceDocumentStatus.NOT_REQUIRED]: GREY[60],
  [ServiceDocumentStatus.COMPLETED]: SUCCESS_PRIMARY,
  [ServiceDocumentStatus.IN_PROGRESS]: WARNING_PRIMARY,
  [ServiceDocumentStatus.NOT_REQUESTED]: WARNING_PRIMARY,
};

const SECONDARY_COLOR: Record<ServiceDocumentStatus, string> = {
  [ServiceDocumentStatus.NOT_REQUIRED]: GREY[20],
  [ServiceDocumentStatus.COMPLETED]: SUCCESS[20],
  [ServiceDocumentStatus.IN_PROGRESS]: WARNING[20],
  [ServiceDocumentStatus.NOT_REQUESTED]: WARNING[20],
};

const TOOLTIP_TEXT: Record<
  ServiceDocumentStatus,
  (documentType: DocumentType, pdfFileSource: PdfFileSource) => string
> = {
  [ServiceDocumentStatus.NOT_REQUIRED]: (documentType) =>
    `${documentTypeToString(documentType)}s not required`,
  [ServiceDocumentStatus.COMPLETED]: (documentType, pdfFileSource) =>
    `${documentTypeToString(documentType)}s ${getCompletionChoice(pdfFileSource)}`,
  [ServiceDocumentStatus.IN_PROGRESS]: (documentType, pdfFileSource) =>
    `${documentTypeToString(documentType)}s requested, but not ${getCompletionChoice(pdfFileSource)}`,
  [ServiceDocumentStatus.NOT_REQUESTED]: (documentType) =>
    `${documentTypeToString(documentType)}s not requested`,
};

export type ServiceDocumentsStatusIconProps = {
  status: ServiceDocumentStatus;
  pdfFileSource?: PdfFileSource;
  size?: string;
  containerSize?: string;
};

export default function ServiceDocumentsStatusIcon({
  documentType,
  status,
  pdfFileSource,
  size = "16px",
  containerSize = "24px",
  medspaServiceOfferingId,
  providerId,
}: ServiceDocumentsStatusIconProps & {
  documentType: DocumentType;
  medspaServiceOfferingId: string;
  providerId: string;
}) {
  const medspa = useMedspaId();
  const { push } = useRouter();
  const { refetch } = useProtocolsAndStandingOrdersTableQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa,
  });

  const [markDocumentAsRequired] = useMarkDocumentAsRequiredMutation();
  const [markDocumentAsNotRequired] = useMarkDocumentAsNotRequiredMutation();

  const Icon = SERVICE_DOCUMENT_STATUS_ICON[status](documentType);
  const primaryColor = PRIMARY_COLOR[status];
  const secondaryColor = SECONDARY_COLOR[status];
  const tooltipText = TOOLTIP_TEXT[status](documentType, pdfFileSource);

  const markAsRequired = async () => {
    const mark = async () => {
      await markDocumentAsRequired({
        variables: {
          medspaServiceOfferingId,
          documentType,
          providerId,
        },
      });

      await refetch();
    };

    await toast.promise(mark(), {
      loading: "Marking the document as required...",
      success: "The document has been marked as required",
      error: "An error occurred while marking the document as required",
    });
  };

  const markAsNotRequired = async () => {
    const mark = async () => {
      await markDocumentAsNotRequired({
        variables: {
          medspaServiceOfferingId,
          documentType,
          providerId,
        },
      });

      await refetch();
    };

    await toast.promise(mark(), {
      loading: "Marking the document as not required...",
      success: "The document has been marked as not required",
      error: "An error occurred while marking the document as not required",
    });
  };

  const actions = [
    {
      label: `Go to ${documentTypeToString(documentType)}s`,
      onClick: () => push(getDocumentTypeViewURL(documentType)(medspa)),
    },
    {
      label: getActionLabel(status),
      onClick:
        status === ServiceDocumentStatus.NOT_REQUIRED
          ? markAsRequired
          : markAsNotRequired,
    },
  ];

  return (
    <ActionIconContainer
      icon={<Icon color={primaryColor} size={size} />}
      size={containerSize}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      actions={actions}
      tooltipText={tooltipText}
    />
  );
}

function getActionLabel(status: ServiceDocumentStatus) {
  switch (status) {
    case ServiceDocumentStatus.NOT_REQUIRED:
      return "Mark as required";
    case ServiceDocumentStatus.COMPLETED:
    case ServiceDocumentStatus.IN_PROGRESS:
    case ServiceDocumentStatus.NOT_REQUESTED:
      return "Mark as not required";
    default:
      return status satisfies never;
  }
}

function getDocumentTypeIcon(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.SERVICE_PROTOCOL:
      return PinpaperFilledIcon;
    case DocumentType.STANDING_ORDER:
      return BulletListIconSvg;
    default:
      return documentType satisfies never;
  }
}

function documentTypeToString(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.SERVICE_PROTOCOL:
      return "Service Protocol";
    case DocumentType.STANDING_ORDER:
      return "Standing Order";
    default:
      return documentType satisfies never;
  }
}

function getDocumentTypeViewURL(
  documentType: DocumentType
): (medspa: string) => string {
  switch (documentType) {
    case DocumentType.SERVICE_PROTOCOL:
      return (medspa) => `/${medspa}/settings/policies-procedures`;
    case DocumentType.STANDING_ORDER:
      return (medspa) =>
        `/${medspa}/settings/policies-procedures/standing-orders`;
    default:
      return documentType satisfies never;
  }
}

function getCompletionChoice(source: PdfFileSource) {
  switch (source) {
    case PdfFileSource.MANUAL_UPLOAD:
      return "uploaded";
    case PdfFileSource.BOLDSIGN:
      return "completed";
    default:
      return source satisfies never;
  }
}
