import { Stack, Typography } from "@mui/material";
import { format, differenceInMinutes } from "date-fns";
import { TIME_FORMATS } from "@/config";
import {
  ExtendedEventPropsCommon,
  MoxieEventContentArg,
  MoxieEventImpl,
  SpecialShiftExtendedEventProps,
} from "@/types/calendarEventType";

export default function SpecialShiftCustomTile({
  event,
  timeText,
}: MoxieEventContentArg<SpecialShiftExtendedEventProps>) {
  const {
    title,
    start,
    end,
    extendedProps: { allDay },
  } = event;

  const time =
    timeText ||
    `${format(start, TIME_FORMATS.COMPACT)} - ${format(
      end,
      TIME_FORMATS.COMPACT
    )}`;
  const displayedTime = allDay ? "All day" : time;

  const duration = differenceInMinutes(end, start);
  if (duration < 30) {
    return <ShortSpecialShiftTile event={event} start={time.split(" - ")[0]} />;
  }
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="labelTiny" color="text.secondary">
          {displayedTime}
        </Typography>
      </Stack>
      <Typography variant="labelTiny" color="text.secondary">
        {title}
      </Typography>
    </Stack>
  );
}

function ShortSpecialShiftTile({
  event,
  start,
}: {
  event: MoxieEventImpl<ExtendedEventPropsCommon>;
  start: string;
}) {
  const { title } = event;
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="labelTiny" color="text.secondary">
        {[start, title].filter(Boolean).join(" • ")}
      </Typography>
    </Stack>
  );
}
