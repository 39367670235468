import { useMemo } from "react";
import { AvailabilityEvent } from "@/__generated__/schema.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { BLOCK_PERIOD } from "@/types";
import { getUserCalendarColor } from "@/utils";
import useProviderIndex from "./useProviderIndex";

type AvailabilityEventExcerpt = Pick<
  AvailabilityEvent,
  "id" | "startTime" | "endTime" | "label" | "allDay" | "eventType"
> & {
  userMedspa: {
    userId: AvailabilityEvent["userMedspa"]["userId"];
    user: Pick<
      AvailabilityEvent["userMedspa"]["user"],
      "firstName" | "lastName" | "id"
    >;
  };
};

export default function useAvailabilityEvents(
  availabilityEventData: AvailabilityEventExcerpt[]
) {
  const { utcToZonedTimeIso } = useMedspaTimezone();

  const getProviderIndex = useProviderIndex();

  const { timeBlockEvents, specialShiftsEvents } = useMemo(() => {
    const timeBlockEvents = [];
    const specialShiftsEvents = [];

    (availabilityEventData ?? []).forEach(
      ({ id, startTime, endTime, label, allDay, eventType, userMedspa }) => {
        const isBlockPeriod = eventType === BLOCK_PERIOD;
        const providerIndex = getProviderIndex(userMedspa.user.id);
        const { colorObjectByIndex } = getUserCalendarColor({
          ...userMedspa.user,
          userIndex: providerIndex,
        });

        const event = {
          id,
          resourceId: userMedspa.userId,
          title: label || (isBlockPeriod ? "Time block" : "Special shift"),
          classNames: isBlockPeriod ? [] : ["availability-constraint-tile"],
          start: utcToZonedTimeIso(startTime),
          end: utcToZonedTimeIso(endTime),
          extendedProps: {
            isTimeBlock: isBlockPeriod,
            isSpecialShift: !isBlockPeriod,
            allDay,
            eventColorPalette: colorObjectByIndex,
          } as const,
          ...(colorObjectByIndex && {
            backgroundColor: colorObjectByIndex[40],
            borderColor: colorObjectByIndex[60],
            textColor: colorObjectByIndex[100],
          }),
        };

        if (isBlockPeriod) return timeBlockEvents.push(event);

        return specialShiftsEvents.push(event);
      }
    );
    return { timeBlockEvents, specialShiftsEvents };
  }, [availabilityEventData, getProviderIndex, utcToZonedTimeIso]);

  return { timeBlockEvents, specialShiftsEvents };
}
