import { Box } from "@mui/material";
import CamIconSvg from "@/components/common/icons/camIconSvg";
import PhoneIconSvg from "@/components/common/icons/phoneIconSvg";
import { BLUE } from "@/config/mui/colorPalette";
import { VisitType } from "@/types";

type TelehealthDetails = {
  visitType: string;
  videoLink?: string;
};

export const getTelehealthIcon = (
  telehealthDetails?: TelehealthDetails,
  iconSize: string = "24px",
  iconColor: string = BLUE[80]
) => {
  const getIcon = () => {
    if (!telehealthDetails) {
      return <CamIconSvg size={iconSize} color={iconColor} />;
    }

    const { visitType, videoLink } = telehealthDetails;
    const type = visitType.toLocaleLowerCase();

    if (type === VisitType.VIDEO) {
      return (
        <CamIconSvg
          size={iconSize}
          set={videoLink ? "duotone" : "outline"}
          color={iconColor}
        />
      );
    }

    if (type === VisitType.PHONE) {
      return <PhoneIconSvg size={iconSize} color={iconColor} />;
    }

    return null;
  };

  const icon = getIcon();

  if (!icon) return null;

  return <Box sx={{ minWidth: iconSize, minHeight: iconSize }}>{icon}</Box>;
};
