import { WatchQueryFetchPolicy } from "@apollo/client";
import { isAfter } from "date-fns";
import { useCallback, useMemo } from "react";
import { useUser } from "@/auth/useUser";
import {
  ServiceDocumentStatus,
  DocumentType,
} from "@/components/common/complianceHub/protocolsStandingOrders/serviceDocumentsStatusIcon";
import {
  ProtocolsAndStandingOrdersTableQuery,
  useProtocolsAndStandingOrdersTableQuery,
} from "@/graphql/queries/complianceHub/protocolsAndStandingOrdersTable.graphql.types";

export default function useProtocolsAndStandingOrdersTableData({
  fetchPolicy,
  skip,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
}) {
  const { medspa } = useUser();

  const { data } = useProtocolsAndStandingOrdersTableQuery({
    variables: {
      medspaId: medspa,
    },
    skip: skip || !medspa,
    fetchPolicy,
  });

  const { offerings, providers } = useMemo(
    () => ({
      offerings: data?.medspaServiceOffering || [],
      providers: data?.providers || [],
    }),
    [data]
  );

  const getDocumentStatus = useCallback(
    (
      document: { status: string; created: string } | undefined,
      notRequiringDocument: { created: string } | undefined
    ): ServiceDocumentStatus => {
      if (document && notRequiringDocument) {
        return isAfter(
          new Date(document.created),
          new Date(notRequiringDocument.created)
        )
          ? (document.status as ServiceDocumentStatus)
          : ServiceDocumentStatus.NOT_REQUIRED;
      }

      if (document) {
        return document.status as ServiceDocumentStatus;
      }

      if (notRequiringDocument) {
        return ServiceDocumentStatus.NOT_REQUIRED;
      }

      return ServiceDocumentStatus.NOT_REQUESTED;
    },
    []
  );

  const findNotRequiredDocument = useCallback(
    (
      collection: ProtocolsAndStandingOrdersTableQuery["providers"][number]["providerNotRequireDocuments"],
      docType: DocumentType,
      offeringId: string
    ) =>
      collection.find(
        ({ documentType, medspaServiceOfferingId }) =>
          documentType === docType && medspaServiceOfferingId === offeringId
      ),
    []
  );

  const rows = useMemo(() => {
    return providers.map((provider) => {
      const name = `${provider.user.firstName} ${provider.user.lastName}`;

      const values = offerings.map(({ id: offeringId, serviceType }) => {
        const protocol = provider.providerServiceProtocols.find(
          (protocol) => protocol.serviceTypeId === serviceType.id
        );

        const notRequiringProtocol = findNotRequiredDocument(
          provider.providerNotRequireDocuments,
          DocumentType.SERVICE_PROTOCOL,
          offeringId
        );

        const standingOrder = provider.providerStandingOrders.find(
          (standingOrder) => standingOrder.serviceTypeId === serviceType.id
        );

        const notRequiringStandingOrder = findNotRequiredDocument(
          provider.providerNotRequireDocuments,
          DocumentType.STANDING_ORDER,
          offeringId
        );

        return {
          protocol: {
            status: getDocumentStatus(protocol, notRequiringProtocol),
            pdfFileSource: protocol?.pdfFileSource,
          },
          standingOrder: {
            status: getDocumentStatus(standingOrder, notRequiringStandingOrder),
            pdfFileSource: standingOrder?.pdfFileSource,
          },
          medspaServiceOfferingId: offeringId,
        };
      });

      return {
        providerId: provider.id,
        name,
        values,
      };
    });
  }, [providers, offerings, getDocumentStatus, findNotRequiredDocument]);

  const issues = useMemo(() => {
    if (!rows) return 0;

    return rows
      .flatMap((row) => row.values)
      .flatMap(({ protocol, standingOrder }) => [
        protocol.status,
        standingOrder.status,
      ])
      .filter((status) =>
        [
          ServiceDocumentStatus.NOT_REQUESTED,
          ServiceDocumentStatus.IN_PROGRESS,
        ].includes(status)
      ).length;
  }, [rows]);

  return {
    isLoading: !data,
    columns: [
      { id: "providerName", label: "Provider name" },
      ...offerings.map(({ serviceType }) => ({
        id: serviceType.id,
        label: serviceType.name,
      })),
    ],
    rows: rows || [],
    issues,
  };
}
