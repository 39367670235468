import { useCallback } from "react";
import { useIsMoxieMobile } from "./useIsMoxieMobile";

export const useMoxieMobileMessageEmitter = () => {
  const { isMoxieMobile } = useIsMoxieMobile();

  const emit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (type: string, payload?: any) => {
      if (!isMoxieMobile || typeof window === "undefined") {
        return;
      }

      const message = JSON.stringify({ type, payload });
      window.ReactNativeWebView?.postMessage(message);
    },
    [isMoxieMobile]
  );

  return { emit };
};
