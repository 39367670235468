import { Box, Typography, TextField, Autocomplete } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { useState, useMemo } from "react";
import { Controller, Control, FieldErrors } from "react-hook-form";
import { VIOLET } from "@/config/mui/colorPalette";
import useSearchQuery from "@/hooks/misc/useSearchQuery";

type Option = {
  id: string;
  label: string;
};

type ComplianceAutocompleteFieldProps = {
  control: Control;
  errors: FieldErrors;
  name: string;
  label: string;
  options: Option[];
  placeholder: string;
  required?: boolean;
};

export default function ComplianceAutocompleteField({
  control,
  errors,
  name,
  label,
  options,
  placeholder,
  required = false,
}: ComplianceAutocompleteFieldProps) {
  const [inputValue, setInputValue] = useState("");
  const { searchQuery, handleSearchInput } = useSearchQuery();

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [options, searchQuery]);

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Typography variant="labelTiny" color="text.secondary" gutterBottom>
              {label}{" "}
              {required && (
                <Typography color="error" component="span">
                  *
                </Typography>
              )}
            </Typography>
            <Autocomplete
              {...field}
              multiple
              options={filteredOptions}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  placeholder={placeholder}
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleSearchInput(e);
                  }}
                  value={inputValue}
                />
              )}
              onChange={(_, value) => field.onChange(value)}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={(option) =>
                field.value.some((item: Option) => item.id === option.id)
              }
              filterOptions={(x) => x} // Disable built-in filtering
              slotProps={{
                paper: {
                  sx: { boxShadow: shadows[4], borderRadius: 2, mt: 1 },
                },
              }}
              ChipProps={{
                sx: {
                  backgroundColor: VIOLET[40],
                  color: VIOLET[100],
                  "& .MuiChip-deleteIcon": {
                    color: VIOLET[100],
                  },
                },
              }}
            />
          </>
        )}
      />
    </Box>
  );
}
