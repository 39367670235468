import { useRouter } from "next/router";
import { useEffect } from "react";
import { useUser } from "@/auth/useUser";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import { useAnalytics } from "./AnalyticsContext";

const RouterListener = ({
  disablePageTracking,
}: {
  disablePageTracking: boolean;
}) => {
  const analytics = useAnalytics();
  const { user, medspa: medspaId, additionalUserDetails } = useUser();
  const { pathname, asPath, query } = useRouter();

  // client side SPA page load
  useEffect(() => {
    if (!disablePageTracking) {
      analytics.page("", { moxiePath: pathname }, { groupId: query?.medspaId });
    }
  }, [pathname, asPath]);

  const { id, displayName, email } = user || {};
  useEffectOnce(!!id && !!displayName && !!email, () => {
    analytics.identify(id, {
      name: displayName,
      email: email,
    });
  });

  const userMedspa = additionalUserDetails?.userMedspa.find(
    (um) => um.medspa.id === medspaId
  );

  useEffectOnce(!!userMedspa, () => {
    analytics.group(userMedspa.medspa.id, {
      name: userMedspa.medspa.name,
    });
  });

  return null;
};

export default RouterListener;
