import { Stack, Typography, Box } from "@mui/material";
import { format, differenceInMinutes } from "date-fns";
import { useRef } from "react";
import { CalendarCrossIcon } from "@/components/common/icons";
import TimeBlockDropdown from "@/components/serviceFlow/timeBlocks/timeblockDropdown";
import { TIME_FORMATS } from "@/config";
import { GREY } from "@/config/mui/colorPalette";
import {
  MoxieEventContentArg,
  TimeBlockExtendedEventProps,
} from "@/types/calendarEventType";

export function TimeBlockCustomTile({
  event,
  timeText,
}: MoxieEventContentArg<TimeBlockExtendedEventProps>) {
  const {
    title,
    id,
    start,
    end,
    extendedProps: {
      allDay,
      showDropdown,
      eventColorPalette,
      clickable = true,
    },
  } = event;

  const ref = useRef<HTMLDivElement>(null);

  const time =
    timeText ||
    `${format(start, TIME_FORMATS.COMPACT)} - ${format(end, TIME_FORMATS.COMPACT)}`;

  const duration = differenceInMinutes(end, start);
  const isEventDurationShort = duration <= 30;

  const widthSize = ref.current?.clientWidth;
  const isLarge = widthSize ? widthSize > 80 : true;

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        cursor: clickable ? "pointer" : "default",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "100%" }}
        ref={ref}
      >
        <Stack
          flexDirection={isEventDurationShort ? "row" : "column"}
          justifyContent={allDay ? "flex-start" : "space-between"}
          alignItems={isEventDurationShort ? "center" : "baseline"}
          sx={{
            height: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Box display="flex" alignItems="center" gap={0.5} pl={0.5}>
            {isLarge && (
              <CalendarCrossIcon
                color={eventColorPalette ? eventColorPalette[100] : GREY[70]}
                size={16}
              />
            )}
            <Typography variant="labelTiny" color="text.primary">
              {title}
            </Typography>
          </Box>

          <Typography
            pl={isEventDurationShort ? 1 : 0.5}
            variant="labelTiny"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {allDay ? "All day" : time}
          </Typography>
        </Stack>
        <Stack sx={{ height: "100%" }} mt={showDropdown ? -3 : 0}>
          {showDropdown && <TimeBlockDropdown id={id} />}
        </Stack>
      </Stack>
    </Stack>
  );
}
