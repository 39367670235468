import { PRIMARY } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
  color?: string;
};

export default function PhoneIconSvg({ size = "24", color = PRIMARY }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M20.4996 16.4643L20.4994 16.4643V16.4767L20.4994 19.1864L20.4994 19.1884C20.5024 19.9309 19.8225 20.5627 19.0714 20.4949L19.0488 20.4929L19.026 20.4929C10.2775 20.4998 3.50021 13.6604 3.50706 4.96958L3.50708 4.94679L3.50503 4.9241C3.43763 4.17976 4.06438 3.50093 4.80834 3.50009H7.52324V3.50011L7.52816 3.50006C7.84634 3.49693 8.15466 3.60939 8.39567 3.81625C8.55629 3.95412 8.73555 4.20769 8.90914 4.57055C9.07886 4.9253 9.22647 5.3491 9.34108 5.78992C9.57651 6.69549 9.64629 7.56727 9.55387 7.9998C9.49081 8.29487 9.30314 8.57732 9.0146 8.88249C8.8723 9.033 8.71556 9.17883 8.54988 9.33016C8.52671 9.35132 8.50325 9.3727 8.47959 9.39425C8.3388 9.5225 8.19116 9.657 8.057 9.79089L7.78676 10.0606L7.97576 10.3923C9.30874 12.732 11.2497 14.669 13.5936 15.9992L13.9244 16.187L14.1937 15.9183C14.3278 15.7844 14.4625 15.6371 14.5909 15.4967C14.6126 15.473 14.6341 15.4495 14.6553 15.4264C14.8069 15.261 14.9531 15.1046 15.1039 14.9626C15.4096 14.6747 15.6929 14.4871 15.9892 14.424C16.424 14.3315 17.3014 14.4017 18.2113 14.6381C18.6544 14.7532 19.08 14.9015 19.4358 15.0721C19.7998 15.2466 20.053 15.4266 20.1897 15.5876L20.1897 15.5876C20.3974 15.8322 20.5075 16.1442 20.4996 16.4643Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M20.9994 19.1864V16.4767C21.0104 16.0337 20.8579 15.6021 20.5708 15.264C19.7614 14.3106 16.9854 13.7008 15.885 13.935C15.0273 14.1176 14.4271 14.9788 13.8404 15.5644C11.5746 14.2785 9.69857 12.4062 8.41019 10.1448C8.99689 9.55929 9.85987 8.96036 10.0428 8.10428C10.2771 7.00777 9.66812 4.24949 8.72131 3.43684C8.38828 3.151 7.96246 2.99577 7.52324 3.00009H4.8081C3.77357 3.00106 2.91287 3.92895 3.00706 4.96919C2.99999 13.935 10 21 19.0264 20.9929C20.0722 21.0873 21.0036 20.2223 20.9994 19.1864Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
