import { useEffect, useState } from "react";

const getIsMoxieMobile = () => {
  if (typeof navigator === "undefined") {
    return false;
  }
  return /Moxie Suite/.test(navigator.userAgent);
};

// TODO: Compute isMoxieMobile on the server side
// not in the useEffect to avoid rendering the header.
// This requires modifying getServerSideProps in every page
export const useIsMoxieMobile = () => {
  const [isMoxieMobile, setIsMoxieMobile] = useState(false);

  useEffect(() => {
    setIsMoxieMobile(getIsMoxieMobile());
  }, []);

  return { isMoxieMobile };
};
