import { Chip } from "@mui/material";
import {
  CompletedGfeIcon,
  ContraindicatedIcon,
  FormExpiredIcon,
  FormUnsentIcon,
  IncompleteFormIcon,
  IndicatedIcon,
  NotIndicatedIcon,
  ReviewRequestedIcon,
  ServiceContraindicatedIcon,
  ServiceIncompleteFormIconBordered,
  ServiceIndicatedIcon,
  ServiceNotIndicatedIcon,
  ServiceReviewRequestedIconBordered,
} from "@/components/common/gfe/gfeIcons";
import {
  ERROR_PRIMARY,
  GREEN,
  GREY,
  MINT,
  SALMON,
  TEXT_PRIMARY_DARK,
  WHITE,
  YELLOW,
} from "@/config/mui/colorPalette";
import {
  FormStatus,
  FormStatusExtra,
  IndicationType,
  ReviewCardGfeStatus,
  ServiceMenuItemGfeStatus,
} from "@/types/gfe";

export enum GfeChipType {
  FORMS_INCOMPLETE = "forms_incomplete",
  FORMS_EXPIRED = "forms_expired",
  FORMS_UNSENT = "forms_unsent",
  PENDING_REVIEWER = "pending_reviewer",
  COMPLETED = "completed",
  NOT_INDICATED = "not_indicated",
  CONTRAINDICATED = "contraindicated",
  INDICATED_WITH_EXCEPTIONS = "indicated_with_exceptions",
  INDICATED = "indicated",
}

type GfeSummaryType =
  | IndicationType
  | FormStatus.INCOMPLETE
  | FormStatus.EXPIRED
  | FormStatusExtra.UNSENT;

type GfeTypeToMap =
  | ServiceMenuItemGfeStatus
  | GfeSummaryType
  | ReviewCardGfeStatus;

export const mapToGfeChipType: Omit<
  Record<GfeTypeToMap, GfeChipType>,
  ServiceMenuItemGfeStatus.NOT_NEEDED
> = {
  [ServiceMenuItemGfeStatus.FORMS_INCOMPLETE]: GfeChipType.FORMS_INCOMPLETE,
  [ServiceMenuItemGfeStatus.PENDING_REVIEWER]: GfeChipType.PENDING_REVIEWER, // satisfies ReviewCardGfeStatus.PENDING_REVIEWER
  [ServiceMenuItemGfeStatus.NOT_INDICATED]: GfeChipType.NOT_INDICATED, // satisfies IndicationType.NOT_INDICATED
  [ServiceMenuItemGfeStatus.CONTRAINDICATED]: GfeChipType.CONTRAINDICATED, // satisfies IndicationType.CONTRAINDICATED
  [ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS]:
    GfeChipType.INDICATED_WITH_EXCEPTIONS,
  [ServiceMenuItemGfeStatus.INDICATED]: GfeChipType.INDICATED, // satisfies IndicationType.INDICATED
  [IndicationType.REVIEW_REQUESTED]: GfeChipType.PENDING_REVIEWER,
  [FormStatus.INCOMPLETE]: GfeChipType.FORMS_INCOMPLETE,
  [FormStatus.EXPIRED]: GfeChipType.FORMS_EXPIRED,
  [FormStatusExtra.UNSENT]: GfeChipType.FORMS_UNSENT,
  [ReviewCardGfeStatus.COMPLETED]: GfeChipType.COMPLETED,
};

export const GFE_CHIP_ICON: Record<
  GfeChipType,
  ({ size }: { size?: string }) => JSX.Element
> = {
  [GfeChipType.FORMS_INCOMPLETE]: IncompleteFormIcon,
  [GfeChipType.FORMS_EXPIRED]: FormExpiredIcon,
  [GfeChipType.FORMS_UNSENT]: FormUnsentIcon,
  [GfeChipType.PENDING_REVIEWER]: ReviewRequestedIcon,
  [GfeChipType.COMPLETED]: CompletedGfeIcon,
  [GfeChipType.NOT_INDICATED]: NotIndicatedIcon,
  [GfeChipType.CONTRAINDICATED]: ContraindicatedIcon,
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: IndicatedIcon,
  [GfeChipType.INDICATED]: IndicatedIcon,
};

export const GFE_SERVICE_STATUS_ICON: Record<
  Exclude<ServiceMenuItemGfeStatus, ServiceMenuItemGfeStatus.NOT_NEEDED>,
  ({ size }: { size?: string }) => JSX.Element
> = {
  [ServiceMenuItemGfeStatus.FORMS_INCOMPLETE]:
    ServiceIncompleteFormIconBordered,
  [ServiceMenuItemGfeStatus.PENDING_REVIEWER]:
    ServiceReviewRequestedIconBordered,
  [ServiceMenuItemGfeStatus.NOT_INDICATED]: ServiceNotIndicatedIcon,
  [ServiceMenuItemGfeStatus.CONTRAINDICATED]: ServiceContraindicatedIcon,
  [ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS]: ServiceIndicatedIcon,
  [ServiceMenuItemGfeStatus.INDICATED]: ServiceIndicatedIcon,
};

export const CHIP_BORDER_COLOR: Record<GfeChipType, string> = {
  [GfeChipType.FORMS_INCOMPLETE]: ERROR_PRIMARY,
  [GfeChipType.FORMS_EXPIRED]: ERROR_PRIMARY,
  [GfeChipType.FORMS_UNSENT]: GREY[50],
  [GfeChipType.PENDING_REVIEWER]: YELLOW[80],
  [GfeChipType.COMPLETED]: GREEN[80],
  [GfeChipType.NOT_INDICATED]: "transparent",
  [GfeChipType.CONTRAINDICATED]: "transparent",
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: "transparent",
  [GfeChipType.INDICATED]: "transparent",
};

export const CHIP_BG_COLOR: Record<GfeChipType, string> = {
  [GfeChipType.FORMS_INCOMPLETE]: WHITE,
  [GfeChipType.FORMS_EXPIRED]: WHITE,
  [GfeChipType.FORMS_UNSENT]: WHITE,
  [GfeChipType.PENDING_REVIEWER]: WHITE,
  [GfeChipType.COMPLETED]: WHITE,
  [GfeChipType.NOT_INDICATED]: GREY[30],
  [GfeChipType.CONTRAINDICATED]: SALMON[40],
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: MINT[40],
  [GfeChipType.INDICATED]: MINT[40],
};

export const GFE_CHIP_TEXT_COLOR: Record<GfeChipType, string> = {
  [GfeChipType.FORMS_INCOMPLETE]: SALMON[100],
  [GfeChipType.FORMS_EXPIRED]: SALMON[100],
  [GfeChipType.FORMS_UNSENT]: TEXT_PRIMARY_DARK,
  [GfeChipType.PENDING_REVIEWER]: YELLOW[100],
  [GfeChipType.COMPLETED]: GREEN[100],
  [GfeChipType.NOT_INDICATED]: TEXT_PRIMARY_DARK,
  [GfeChipType.CONTRAINDICATED]: SALMON[100],
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: MINT[100],
  [GfeChipType.INDICATED]: MINT[100],
};

export const GFE_CHIP_TEXT: Record<GfeChipType, string> = {
  [GfeChipType.FORMS_INCOMPLETE]: "Client forms incomplete",
  [GfeChipType.FORMS_EXPIRED]: "Client forms expired",
  [GfeChipType.FORMS_UNSENT]: "Client forms unsent",
  [GfeChipType.PENDING_REVIEWER]: "Pending reviewer",
  [GfeChipType.COMPLETED]: "Completed",
  [GfeChipType.NOT_INDICATED]: "Not Indicated",
  [GfeChipType.CONTRAINDICATED]: "Contraindicated",
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: "Indicated with exceptions",
  [GfeChipType.INDICATED]: "Indicated",
};

const getIsSmallIcon = (gfeType: GfeChipType) =>
  [
    GfeChipType.FORMS_INCOMPLETE,
    GfeChipType.FORMS_EXPIRED,
    GfeChipType.FORMS_UNSENT,
    GfeChipType.PENDING_REVIEWER,
    GfeChipType.COMPLETED,
  ].includes(gfeType);

export type GfeIndicationChipProps = {
  type: GfeTypeToMap;
  text?: string | number;
  variant?: "small" | "medium";
};

export function GfeIndicationChip({
  type,
  text,
  variant = "medium",
}: GfeIndicationChipProps) {
  const chipType = mapToGfeChipType[type];

  const Icon = GFE_CHIP_ICON[chipType];
  const isSmallIcon = getIsSmallIcon(chipType);
  const label = text ? text : GFE_CHIP_TEXT[chipType];

  return (
    <Chip
      sx={{
        minWidth: 0,
        width: "fit-content",
        "&.MuiChip-root": {
          bgcolor: CHIP_BG_COLOR[chipType],
          borderColor: CHIP_BORDER_COLOR[chipType],
          color: GFE_CHIP_TEXT_COLOR[chipType],
          pl: isSmallIcon ? 1 : 0.5,
        },
        "& .MuiChip-label": {
          pl: isSmallIcon ? 0.5 : 0,
        },
      }}
      size={variant}
      variant="outlined"
      icon={Icon && <Icon size={isSmallIcon ? "20px" : "24px"} />}
      label={label}
    />
  );
}
