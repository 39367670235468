import { datadogRum } from "@datadog/browser-rum";
import { AuthUser } from "@/auth/useUser";
import {
  HASURA_IMPERSONATED_ID,
  HASURA_IMPERSONATOR_ID,
  HasuraClaimsType,
} from "@/types";

export default function trackUserSessionReplay(
  user: AuthUser,
  hasuraClaims: HasuraClaimsType
) {
  const isImpersonation = !!hasuraClaims[HASURA_IMPERSONATED_ID];
  const sanitizedEmail =
    user.role === "client" ? "PATIENT_EMAIL_REDACTED" : user.email;

  // Configure Datadog RUM
  datadogRum.setUser({
    id: user.id,
    email: sanitizedEmail,
    role: user.role,
    is_impersonation_session: isImpersonation,
  });
  if (isImpersonation) {
    setImpersonationProperties(hasuraClaims);
  }

  if (typeof window === "undefined") return;
  // Configure Livesession if enabled
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ls = (window as any)?.__ls;
  if (ls) {
    ls("identify", {
      email: sanitizedEmail,
      params: {
        user_id: user.id,
        role: user.role,
        is_impersonation_session: isImpersonation,
        ...(isImpersonation
          ? {
              impersonated_id: hasuraClaims[HASURA_IMPERSONATED_ID],
              impersonator_id: hasuraClaims[HASURA_IMPERSONATOR_ID],
            }
          : {}),
      },
    });
  }
}

function setImpersonationProperties(hasuraClaims: HasuraClaimsType) {
  datadogRum.setUserProperty(
    "impersonated_id",
    hasuraClaims[HASURA_IMPERSONATED_ID]
  );
  datadogRum.setUserProperty(
    "impersonator_id",
    hasuraClaims[HASURA_IMPERSONATOR_ID]
  );
}
