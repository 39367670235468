import {
  addDays,
  differenceInWeeks,
  startOfDay,
  startOfToday,
  subMinutes,
  subWeeks,
} from "date-fns";
import { useRouter } from "next/router";
import useMedspaTimezone from "../common/useMedspaTimezone";
import { CalendarViews } from "./useCalendarTypeView";

const DEFAULT_BUFFER_DAYS = 6;

export function useDateToMedspaWeekRange(
  calendarStartDate: Date,
  calendarDateRange?: { startDate: Date; endDate: Date }
) {
  const {
    addTimezoneOffset,
    getMedspaStartOfDay,
    getMedspaEndOfDay,
    medspaReadableTimezone,
  } = useMedspaTimezone();

  const {
    query: { view },
  } = useRouter();

  if (!medspaReadableTimezone) return null;

  const calendarDate = startOfDay(calendarStartDate);

  if (
    (view === CalendarViews.WEEK || view === CalendarViews.MONTH) &&
    calendarDateRange !== undefined
  ) {
    return {
      startDate: getMedspaStartOfDay(calendarDateRange.startDate).toISOString(),
      endDate: getMedspaEndOfDay(
        // Calendar end date is exclusive (e.g: for week 2-8, the end date will be Sun Jun 09 2024 00:00:00 ),
        // which causes end of day functions to add another 24h, so we need to subtract 1 minute
        subMinutes(calendarDateRange.endDate, 1)
      ).toISOString(),
      zonedDate: addTimezoneOffset(calendarDate), // Used only in unit tests
    };
  }

  const today = startOfToday();
  const weeksDifference = differenceInWeeks(today, calendarDate, {
    roundingMethod: "ceil",
  });

  const localStartDate = subWeeks(today, weeksDifference);

  return {
    startDate: getMedspaStartOfDay(localStartDate).toISOString(),
    // Loads by default 7 extra days, as to buffer some events
    endDate: getMedspaEndOfDay(
      addDays(localStartDate, DEFAULT_BUFFER_DAYS)
    ).toISOString(),
    zonedDate: addTimezoneOffset(calendarDate), // Used only in unit tests
  };
}
