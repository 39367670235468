import { useRouter } from "next/router";

export const useIsOnlineStoreOTPPage = () => {
  const { pathname } = useRouter();

  return (
    pathname.includes(
      "/online-store/[medspaSlug]/[tab]/[membershipId]/confirm"
    ) ||
    pathname.includes("/online-store/[medspaSlug]/sign-in") ||
    pathname.includes("/online-store/[medspaSlug]/[tab]/[membershipId]/sign-up")
  );
};

export default useIsOnlineStoreOTPPage;
