import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SumUnreadHighlevelMessagesQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessages?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type SumUnreadHighlevelMessagesForClientQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  clientId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesForClientQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessagesByClient?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type HighlevelMessagesByConversationIdQueryVariables = Types.Exact<{
  conversationId: Types.Scalars["String"]["input"];
  locationId: Types.Scalars["String"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  lastMessageId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type HighlevelMessagesByConversationIdQuery = {
  __typename?: "query_root";
  highlevelMessagesByConversationId?: {
    __typename?: "MessagesType";
    lastMessageId: string;
    nextPage: boolean;
    messages: Array<{
      __typename?: "MessageType";
      id: string;
      userId?: string | null;
      body?: string | null;
      locationId: string;
      contactId: string;
      conversationId: string;
      dateAdded?: string | null;
      direction: Types.MessageDirectionType;
      status?: Types.MessageStatusType | null;
      attachments?: Array<string | null> | null;
      meta?: string | null;
      source?: string | null;
      messageType?: Types.MessageTypeType | null;
    } | null>;
  } | null;
};

export type HighLevelSearchConversationsQueryVariables = Types.Exact<{
  locationId: Types.Scalars["String"]["input"];
  contactId: Types.Scalars["String"]["input"];
  limit: Types.Scalars["Int"]["input"];
  startAfter?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  sortBy?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  sortOrder?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type HighLevelSearchConversationsQuery = {
  __typename?: "query_root";
  highlevelSearchConversations?: {
    __typename?: "ConversationsType";
    total?: number | null;
    conversations?: Array<{
      __typename?: "ConversationType";
      id: string;
      contactId?: string | null;
      locationId?: string | null;
      lastMessageBody?: string | null;
      lastMessageType: Types.ConversationLastMessageTypeValues;
      type: Types.ConversationTypeValues;
      unreadCount: number;
      fullName?: string | null;
      contactName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null> | null;
  } | null;
};

export type GetHighLevelBaseUrlQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type GetHighLevelBaseUrlQuery = {
  __typename?: "query_root";
  highlevelBaseUrl?: string | null;
};

export type HighLevelGetContactByIdQueryVariables = Types.Exact<{
  contactId: Types.Scalars["String"]["input"];
  locationId: Types.Scalars["String"]["input"];
}>;

export type HighLevelGetContactByIdQuery = {
  __typename?: "query_root";
  highlevelGetContactById?: {
    __typename?: "ContactType";
    id?: string | null;
    name?: string | null;
    locationId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    emailLowerCase?: string | null;
    timeZone?: string | null;
    companyName?: string | null;
    phone?: string | null;
    dnd?: boolean | null;
    type?: string | null;
    source?: string | null;
    assignedTo?: string | null;
    address1?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    postalCode?: string | null;
    website?: string | null;
    tags?: Array<string | null> | null;
    dateOfBirth?: string | null;
    dateAdded?: string | null;
    attachments?: Array<string | null> | null;
    ssn?: string | null;
    keyword?: string | null;
    firstNameLowerCase?: string | null;
    fullNameLowerCase?: string | null;
    lastNameLowerCase?: string | null;
    lastActivity?: string | null;
    businessId?: string | null;
    dndSettings?: {
      __typename?: "DNDSettingsType";
      call?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
      email?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
      sms?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
      whatsApp?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
      gmb?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
      fb?: {
        __typename?: "DNDChannelSettingsType";
        status?: Types.DndStatusType | null;
        message?: string | null;
        code?: string | null;
      } | null;
    } | null;
    customFields?: Array<{
      __typename?: "CustomFieldType";
      id?: string | null;
      value?: string | null;
    } | null> | null;
    attributionSource?: {
      __typename?: "AttributionSourceType";
      url?: string | null;
      campaign?: string | null;
      utmSource?: string | null;
      utmMedium?: string | null;
      utmContent?: string | null;
      referrer?: string | null;
      campaignId?: string | null;
      fbclid?: string | null;
      gclid?: string | null;
      msclkid?: string | null;
      dclid?: string | null;
      fbc?: string | null;
      fbp?: string | null;
      fbEventId?: string | null;
      userAgent?: string | null;
      ip?: string | null;
      medium?: string | null;
      mediumId?: string | null;
    } | null;
    lastAttributionSource?: {
      __typename?: "AttributionSourceType";
      url?: string | null;
      campaign?: string | null;
      utmSource?: string | null;
      utmMedium?: string | null;
      utmContent?: string | null;
      referrer?: string | null;
      campaignId?: string | null;
      fbclid?: string | null;
      gclid?: string | null;
      msclkid?: string | null;
      dclid?: string | null;
      fbc?: string | null;
      fbp?: string | null;
      fbEventId?: string | null;
      userAgent?: string | null;
      ip?: string | null;
      medium?: string | null;
      mediumId?: string | null;
    } | null;
  } | null;
};

export type HighLevelSendNewMessageToConversationMutationVariables =
  Types.Exact<{
    locationId: Types.Scalars["String"]["input"];
    contactId: Types.Scalars["String"]["input"];
    messageType: Types.OutboundMessageTypeValues;
    messageData: Types.SendMessageDataInputType;
  }>;

export type HighLevelSendNewMessageToConversationMutation = {
  __typename?: "mutation_root";
  highLevelSendNewMessageToConversation?: {
    __typename?: "SendMessageResultType";
    conversationId?: string | null;
    messageId?: string | null;
    messageIds?: Array<string | null> | null;
    emailMessageId?: string | null;
    msg?: string | null;
  } | null;
};

export type UpdateHighlevelUnreadMessagesCountMutationVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  clientId: Types.Scalars["Int"]["input"];
  count: Types.Scalars["Int"]["input"];
}>;

export type UpdateHighlevelUnreadMessagesCountMutation = {
  __typename?: "mutation_root";
  updateHighLevelUnreadMessagesCount?: {
    __typename?: "UpdateHighLevelUnreadMessagesCount";
    count?: number | null;
  } | null;
};

export const SumUnreadHighlevelMessagesDocument = gql`
  query SumUnreadHighlevelMessages($medspaId: Int!) {
    sumUnreadHighlevelMessages(medspaId: $medspaId) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export type SumUnreadHighlevelMessagesQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesQuery
>;
export type SumUnreadHighlevelMessagesLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesLazyQuery
>;
export type SumUnreadHighlevelMessagesSuspenseQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesSuspenseQuery
>;
export type SumUnreadHighlevelMessagesQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesQuery,
  SumUnreadHighlevelMessagesQueryVariables
>;
export const SumUnreadHighlevelMessagesForClientDocument = gql`
  query SumUnreadHighlevelMessagesForClient($medspaId: Int!, $clientId: Int!) {
    sumUnreadHighlevelMessagesByClient(
      medspaId: $medspaId
      clientId: $clientId
    ) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesForClientQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesForClientQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesForClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export type SumUnreadHighlevelMessagesForClientQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientQuery
>;
export type SumUnreadHighlevelMessagesForClientLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientLazyQuery
>;
export type SumUnreadHighlevelMessagesForClientSuspenseQueryHookResult =
  ReturnType<typeof useSumUnreadHighlevelMessagesForClientSuspenseQuery>;
export type SumUnreadHighlevelMessagesForClientQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesForClientQuery,
  SumUnreadHighlevelMessagesForClientQueryVariables
>;
export const HighlevelMessagesByConversationIdDocument = gql`
  query HighlevelMessagesByConversationId(
    $conversationId: String!
    $locationId: String!
    $limit: Int = 100
    $lastMessageId: String
  ) {
    highlevelMessagesByConversationId(
      conversationId: $conversationId
      locationId: $locationId
      limit: $limit
      lastMessageId: $lastMessageId
    ) {
      messages {
        id
        userId
        body
        locationId
        contactId
        conversationId
        dateAdded
        direction
        status
        attachments
        meta
        source
        messageType
      }
      lastMessageId
      nextPage
    }
  }
`;

/**
 * __useHighlevelMessagesByConversationIdQuery__
 *
 * To run a query within a React component, call `useHighlevelMessagesByConversationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlevelMessagesByConversationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlevelMessagesByConversationIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      locationId: // value for 'locationId'
 *      limit: // value for 'limit'
 *      lastMessageId: // value for 'lastMessageId'
 *   },
 * });
 */
export function useHighlevelMessagesByConversationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export type HighlevelMessagesByConversationIdQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdQuery
>;
export type HighlevelMessagesByConversationIdLazyQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdLazyQuery
>;
export type HighlevelMessagesByConversationIdSuspenseQueryHookResult =
  ReturnType<typeof useHighlevelMessagesByConversationIdSuspenseQuery>;
export type HighlevelMessagesByConversationIdQueryResult = Apollo.QueryResult<
  HighlevelMessagesByConversationIdQuery,
  HighlevelMessagesByConversationIdQueryVariables
>;
export const HighLevelSearchConversationsDocument = gql`
  query HighLevelSearchConversations(
    $locationId: String!
    $contactId: String!
    $limit: Int!
    $startAfter: String
    $sortBy: String
    $sortOrder: String
  ) {
    highlevelSearchConversations(
      locationId: $locationId
      contactId: $contactId
      limit: $limit
      startAfter: $startAfter
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      conversations {
        id
        contactId
        locationId
        lastMessageBody
        lastMessageType
        type
        unreadCount
        fullName
        contactName
        email
        phone
      }
      total
    }
  }
`;

/**
 * __useHighLevelSearchConversationsQuery__
 *
 * To run a query within a React component, call `useHighLevelSearchConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighLevelSearchConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighLevelSearchConversationsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      contactId: // value for 'contactId'
 *      limit: // value for 'limit'
 *      startAfter: // value for 'startAfter'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useHighLevelSearchConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export function useHighLevelSearchConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export function useHighLevelSearchConversationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export type HighLevelSearchConversationsQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsQuery
>;
export type HighLevelSearchConversationsLazyQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsLazyQuery
>;
export type HighLevelSearchConversationsSuspenseQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsSuspenseQuery
>;
export type HighLevelSearchConversationsQueryResult = Apollo.QueryResult<
  HighLevelSearchConversationsQuery,
  HighLevelSearchConversationsQueryVariables
>;
export const GetHighLevelBaseUrlDocument = gql`
  query GetHighLevelBaseUrl($medspaId: Int!) {
    highlevelBaseUrl(medspaId: $medspaId)
  }
`;

/**
 * __useGetHighLevelBaseUrlQuery__
 *
 * To run a query within a React component, call `useGetHighLevelBaseUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighLevelBaseUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighLevelBaseUrlQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useGetHighLevelBaseUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >(GetHighLevelBaseUrlDocument, options);
}
export function useGetHighLevelBaseUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >(GetHighLevelBaseUrlDocument, options);
}
export function useGetHighLevelBaseUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHighLevelBaseUrlQuery,
    GetHighLevelBaseUrlQueryVariables
  >(GetHighLevelBaseUrlDocument, options);
}
export type GetHighLevelBaseUrlQueryHookResult = ReturnType<
  typeof useGetHighLevelBaseUrlQuery
>;
export type GetHighLevelBaseUrlLazyQueryHookResult = ReturnType<
  typeof useGetHighLevelBaseUrlLazyQuery
>;
export type GetHighLevelBaseUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetHighLevelBaseUrlSuspenseQuery
>;
export type GetHighLevelBaseUrlQueryResult = Apollo.QueryResult<
  GetHighLevelBaseUrlQuery,
  GetHighLevelBaseUrlQueryVariables
>;
export const HighLevelGetContactByIdDocument = gql`
  query HighLevelGetContactById($contactId: String!, $locationId: String!) {
    highlevelGetContactById(contactId: $contactId, locationId: $locationId) {
      id
      name
      locationId
      firstName
      lastName
      email
      emailLowerCase
      timeZone
      companyName
      phone
      dnd
      dndSettings {
        call {
          status
          message
          code
        }
        email {
          status
          message
          code
        }
        sms {
          status
          message
          code
        }
        whatsApp {
          status
          message
          code
        }
        gmb {
          status
          message
          code
        }
        fb {
          status
          message
          code
        }
      }
      type
      source
      assignedTo
      address1
      city
      state
      country
      postalCode
      website
      tags
      dateOfBirth
      dateAdded
      attachments
      ssn
      keyword
      firstNameLowerCase
      fullNameLowerCase
      lastNameLowerCase
      lastActivity
      customFields {
        id
        value
      }
      businessId
      attributionSource {
        url
        campaign
        utmSource
        utmMedium
        utmContent
        referrer
        campaignId
        fbclid
        gclid
        msclkid
        dclid
        fbc
        fbp
        fbEventId
        userAgent
        ip
        medium
        mediumId
      }
      lastAttributionSource {
        url
        campaign
        utmSource
        utmMedium
        utmContent
        referrer
        campaignId
        fbclid
        gclid
        msclkid
        dclid
        fbc
        fbp
        fbEventId
        userAgent
        ip
        medium
        mediumId
      }
    }
  }
`;

/**
 * __useHighLevelGetContactByIdQuery__
 *
 * To run a query within a React component, call `useHighLevelGetContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighLevelGetContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighLevelGetContactByIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useHighLevelGetContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >(HighLevelGetContactByIdDocument, options);
}
export function useHighLevelGetContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >(HighLevelGetContactByIdDocument, options);
}
export function useHighLevelGetContactByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighLevelGetContactByIdQuery,
    HighLevelGetContactByIdQueryVariables
  >(HighLevelGetContactByIdDocument, options);
}
export type HighLevelGetContactByIdQueryHookResult = ReturnType<
  typeof useHighLevelGetContactByIdQuery
>;
export type HighLevelGetContactByIdLazyQueryHookResult = ReturnType<
  typeof useHighLevelGetContactByIdLazyQuery
>;
export type HighLevelGetContactByIdSuspenseQueryHookResult = ReturnType<
  typeof useHighLevelGetContactByIdSuspenseQuery
>;
export type HighLevelGetContactByIdQueryResult = Apollo.QueryResult<
  HighLevelGetContactByIdQuery,
  HighLevelGetContactByIdQueryVariables
>;
export const HighLevelSendNewMessageToConversationDocument = gql`
  mutation HighLevelSendNewMessageToConversation(
    $locationId: String!
    $contactId: String!
    $messageType: OutboundMessageTypeValues!
    $messageData: SendMessageDataInputType!
  ) {
    highLevelSendNewMessageToConversation(
      locationId: $locationId
      contactId: $contactId
      messageType: $messageType
      messageData: $messageData
    ) {
      conversationId
      messageId
      messageIds
      emailMessageId
      msg
    }
  }
`;
export type HighLevelSendNewMessageToConversationMutationFn =
  Apollo.MutationFunction<
    HighLevelSendNewMessageToConversationMutation,
    HighLevelSendNewMessageToConversationMutationVariables
  >;

/**
 * __useHighLevelSendNewMessageToConversationMutation__
 *
 * To run a mutation, you first call `useHighLevelSendNewMessageToConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHighLevelSendNewMessageToConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [highLevelSendNewMessageToConversationMutation, { data, loading, error }] = useHighLevelSendNewMessageToConversationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      contactId: // value for 'contactId'
 *      messageType: // value for 'messageType'
 *      messageData: // value for 'messageData'
 *   },
 * });
 */
export function useHighLevelSendNewMessageToConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HighLevelSendNewMessageToConversationMutation,
    HighLevelSendNewMessageToConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HighLevelSendNewMessageToConversationMutation,
    HighLevelSendNewMessageToConversationMutationVariables
  >(HighLevelSendNewMessageToConversationDocument, options);
}
export type HighLevelSendNewMessageToConversationMutationHookResult =
  ReturnType<typeof useHighLevelSendNewMessageToConversationMutation>;
export type HighLevelSendNewMessageToConversationMutationResult =
  Apollo.MutationResult<HighLevelSendNewMessageToConversationMutation>;
export type HighLevelSendNewMessageToConversationMutationOptions =
  Apollo.BaseMutationOptions<
    HighLevelSendNewMessageToConversationMutation,
    HighLevelSendNewMessageToConversationMutationVariables
  >;
export const UpdateHighlevelUnreadMessagesCountDocument = gql`
  mutation UpdateHighlevelUnreadMessagesCount(
    $medspaId: Int!
    $clientId: Int!
    $count: Int!
  ) {
    updateHighLevelUnreadMessagesCount(
      medspaId: $medspaId
      clientId: $clientId
      count: $count
    ) {
      count
    }
  }
`;
export type UpdateHighlevelUnreadMessagesCountMutationFn =
  Apollo.MutationFunction<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >;

/**
 * __useUpdateHighlevelUnreadMessagesCountMutation__
 *
 * To run a mutation, you first call `useUpdateHighlevelUnreadMessagesCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHighlevelUnreadMessagesCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHighlevelUnreadMessagesCountMutation, { data, loading, error }] = useUpdateHighlevelUnreadMessagesCountMutation({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useUpdateHighlevelUnreadMessagesCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >(UpdateHighlevelUnreadMessagesCountDocument, options);
}
export type UpdateHighlevelUnreadMessagesCountMutationHookResult = ReturnType<
  typeof useUpdateHighlevelUnreadMessagesCountMutation
>;
export type UpdateHighlevelUnreadMessagesCountMutationResult =
  Apollo.MutationResult<UpdateHighlevelUnreadMessagesCountMutation>;
export type UpdateHighlevelUnreadMessagesCountMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >;
