import { Breakpoint, Container, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  maxWidth?: Breakpoint;
  sx?: SxProps<Theme>;
};

export default function ContentContainer({
  children,
  maxWidth = "xl",
  sx,
}: Props) {
  return (
    <Container
      maxWidth={maxWidth}
      component="main"
      sx={{
        boxSizing: "border-box",
        py: 2,
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {children}
    </Container>
  );
}
