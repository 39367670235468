import { debounce } from "@mui/material";
import { useMemo, useRef, useState } from "react";

export default function useSearchQuery(onSearchQueryChange?: () => void) {
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef<HTMLInputElement>();

  /**
   * `useMemo` is used here instead of `useCallback` to have
   * `debounce` function to be called only once.
   *
   * `useCallback` would call `debounce` on each component
   * render and then scrap its result
   */
  const handleSearchInput = useMemo(
    () =>
      debounce((event) => {
        const searchQuery = event.target.value;
        setSearchQuery(searchQuery);
        onSearchQueryChange?.();
      }, 500),
    [onSearchQueryChange]
  );

  // We have to export searchInputRef for this to work because the
  // search component uses defaultValue, so we need to reset the value through the ref
  const resetSearch = () => {
    searchInputRef.current.value = "";
    setSearchQuery("");
  };

  return {
    handleSearchInput,
    searchQuery,
    setSearchQuery,
    resetSearch,
    searchInputRef,
  };
}
