import { init } from "commandbar";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { OrderBy } from "@/__generated__/schema.graphql.types";
import { hasRole, useUser } from "@/auth/useUser";
import { COMMAND_AI_ORG_ID } from "@/config";
import { useClients } from "@/hooks/clients/useClients";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import { ADMIN, FRONT_DESK, MEDICAL_DIRECTOR, PROVIDER } from "@/types";

export default function CommandBar() {
  const router = useRouter();
  const { medspa, user } = useUser();
  const { commandAiEnabled } = useFeatureFlags();
  const { clients } = useClients("", [
    {
      firstName: OrderBy.Asc,
    },
  ]);
  const [initiated, setInitiated] = useState(false);

  // We don't want to initialize CommandBar for clients
  // We pay per "MAU", so we want to avoid unnecessary costs
  const isNotClientRole = hasRole(user, [
    PROVIDER,
    ADMIN,
    MEDICAL_DIRECTOR,
    FRONT_DESK,
  ]);

  const isReadyToInit =
    typeof window !== "undefined" &&
    user?.id &&
    isNotClientRole &&
    medspa &&
    commandAiEnabled;

  // Initialize CommandBar
  useEffectOnce(isReadyToInit, () => {
    // docs: https://www.command.ai/docs/sdk/lifecycle/boot/
    init(COMMAND_AI_ORG_ID);
    const userProperties = {
      medspaId: medspa,
      role: user?.role ?? '',
      userId: user?.id ?? '',
      email: user?.email ?? '',
      name: user?.displayName ?? '',
    };
    window.CommandBar.boot(user.id, userProperties).then(() =>
      setInitiated(true)
    );
  });

  // Add clients to CommandBar Global Search
  useEffect(() => {
    if (initiated && clients && commandAiEnabled) {
      window.CommandBar.addRecords(
        "clients",
        clients.map((client) => ({
          id: client.id,
          name: `${client.firstName} ${client.lastName}`,
          email: client.email,
          phone: client.phone,
          twilioConversationId: client.twilioConversationId,
        })),
        {
          labelKey: "name",
        }
      );
    }
  }, [clients, initiated, commandAiEnabled]);

  // Add router to CommandBar so it can change the URL
  useEffect(() => {
    if (initiated && medspa && commandAiEnabled) {
      window.CommandBar.addRouter((newUrl) =>
        router.push(`/${medspa}${newUrl}`)
      );
    }
  }, [medspa, initiated, router, commandAiEnabled]);

  return null;
}
