// Medspa scoped feature flags (TODO, migrate the remaining global flags to this const)
export const FeatureFlags = {
  BALANCE_V1: "balance-v1",
  CHERRY_PAYMENTS_V1: "cherry-payments-v1",
  PACKAGES_ALLOW_RETAIL_PRODUCTS: "packages-allow-retail-products",
  MESSAGING_MAINTENANCE: "messaging-maintenance-v1",
  XPERIENCE_WALLET_TYPE: "xperience-wallet-type",
  STANDING_ORDERS_V1: "standing-orders-v1",
  AFFIRM_PAYMENT_METHOD: "affirm-payment-method",
  NEW_GFE_FLOW_V1: "new-gfe-flow-v1",
  NEW_GFE_FLOW_V2: "new-gfe-flow-v2",
  VENMO_PAYMENT_METHOD: "venmo-payment-method",
  ZELLE_PAYMENT_METHOD: "zelle-payment-method",
  IS_IN_MAINTENANCE: "maintenance-banner-v1",
  CONSENT_FORM_IN_EVERY_APPT: "consent-form-in-every-appt",
  INVOICE_SUMMARY_UPDATE_SERVICES: "invoice-summary-update-services",
  CHARTS_OVERDUE_V1: "charts-overdue-v1",
  CHARTING_REVAMP_V1: "charting-revamp-v1",
  COMPLIANCE_HUB_V1: "compliance-hub-v1",
  COMPLIANCE_HUB_V2: "compliance-hub-v2",
  COMPLIANCE_HUB_V3: "compliance-hub-v3",
  COMPLIANCE_HUB_V4: "compliance-hub-v4",
  COMPLIANCE_HUB_APPT_HISTORY: "compliance-hub-appt-history",
  COMPLIANCE_MD_MEETINGS_V1: "compliance-md-meetings-v1",
  ADVERSE_REACTIONS_V1: "adverse-reactions-v1",
  SYNC_GFE_V1: "sync-gfe-v1",
  SYNC_GFE_V2: "sync-gfe-v2",
  COMBO_GFE_V1: "combo-gfe-v1",
  MD_CHARTS_V1: "md-charts-v1",
  MARKETING_MESSAGES_V1: "marketing-messages-v1",
  NEW_BOOKING_FLOW_V1: "new-booking-flow-v1",
  REBOOKING_V2: "rebooking-v2",
  CONTENT_VAULT_V1: "content-vault-v1",
  COMMAND_AI: "commandai",
  INTAKE_FORMS_ENHANCEMENTS_V1: "intake-forms-enhancements-v1",
  ONLINE_STORE_V2_CONFIGURATION: "online-store-v2-configuration",
  ONLINE_STORE_V2: "online-store-v2",
  MOXIE_EMAIL_GIFT_BUILDER_V1: "moxie-email-gift-builder-v1",
  MARKETING_MESSAGES_RESPONSE_V1: "marketing-messages-response-v1",
  TELEHEALTH_SERVICES_V1: "telehealth-services-v1",
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];
