import { ChipPropsColorOverrides } from "@mui/material";

// BE: GfeTreatmentIndicationTypeChoices
export enum IndicationType {
  REVIEW_REQUESTED = "review_requested",
  INDICATED = "indicated",
  CONTRAINDICATED = "contraindicated",
  NOT_INDICATED = "not_indicated",
}

// BE: FormSubmissionStatus -> GFEStatus
export enum FormStatus {
  INCOMPLETE = "incomplete",
  COMPLETED = "client_completed",
  EXPIRED = "expired",
}

export enum FormStatusExtra {
  UNSENT = "unsent", // services offered by medspa, but forms weren't sent yet
  NOT_NEEDED = "", // the form's gfe status wasn't set at all, which means no GFE is needed
}

// BE: FormSubmissionStatus -> GFEStatus
// Deprecated: use FormStatus, FormStatusExtra instead, remove when newGfeFlowV1 is enabled for all
export enum GfeStatus {
  INCOMPLETE = "incomplete",
  CLIENT_COMPLETED = "client_completed",
  WAITING_FOR_GFE = "waiting_for_gfe",
  GFE_APPROVED = "gfe_approved",
  GFE_DECLINED = "gfe_declined",
  EXPIRED = "expired",
  NOT_NEEDED = "",
}

export const GfeStatusColors: {
  [key in GfeStatus]: keyof ChipPropsColorOverrides;
} = {
  [GfeStatus.INCOMPLETE]: "red",
  [GfeStatus.CLIENT_COMPLETED]: "red",
  [GfeStatus.WAITING_FOR_GFE]: "yellow",
  [GfeStatus.GFE_APPROVED]: "green",
  [GfeStatus.GFE_DECLINED]: "red",
  [GfeStatus.EXPIRED]: "red",
  [GfeStatus.NOT_NEEDED]: "green",
};

// BE: VisitGFEStatus
export enum DeprecatedFinalGfeVisitStatus {
  FORMS_INCOMPLETE = "forms_incomplete",
  FORMS_COMPLETE = "forms_complete",
  FORMS_EXPIRED = "forms_expired",
  PENDING_REVIEWER = "pending_reviewer",
  APPROVED = "approved",
  DECLINED = "declined",
  NOT_NEEDED = "not_needed",
}

// BE: ServiceMenuItemGFEStatus
export enum ServiceMenuItemGfeStatus {
  FORMS_INCOMPLETE = "forms_incomplete",
  PENDING_REVIEWER = "pending_reviewer",
  NOT_INDICATED = "not_indicated",
  CONTRAINDICATED = "contraindicated",
  INDICATED_WITH_EXCEPTIONS = "indicated_with_exceptions",
  INDICATED = "indicated",
  NOT_NEEDED = "not_needed",
}

export enum ReviewCardGfeStatus {
  PENDING_REVIEWER = "pending_reviewer",
  COMPLETED = "completed",
}

// BE: GfeInputIndicationTypeEnum
export enum SyncGfeIndicationType {
  INDICATED = "indicated",
  CONTRAINDICATED = "contraindicated",
  NOT_INDICATED = "not_indicated",
}

// BE: GfeSynchronousReviewDocument.DeviceChoices
export enum DeviceType {
  PROVIDER = "provider",
  OTHER = "other",
}
