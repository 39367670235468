import Cookies from "js-cookie";
import { useState } from "react";
import { OrderBy } from "@/__generated__/schema.graphql.types";
import { Option } from "@/components/common/sorting/radioOptionsTypes";

export const SORT_CLIENTS_KEY = "CLIENTS_SORT_BY";

export const RECENT_VISITS_SORT_KEY = "visitStartTime"; // custom view
export const NO_SHOW_VISITS_SORT_KEY = "noShowVisitStartTime"; // custom view

const CLIENTS_OLD_ID = "clients-old";
const NAMES_A_Z_ID = "names-a-z";

type SortBy =
  | "created"
  | "firstName"
  | "fullName"
  | typeof RECENT_VISITS_SORT_KEY
  | typeof NO_SHOW_VISITS_SORT_KEY;

type SortDirection = OrderBy.Asc | OrderBy.Desc;

export type Sort = {
  [key in SortBy]?: SortDirection;
};

export type SortArray = {
  field: SortBy;
  direction: SortDirection;
};

export type SortOption = Option & {
  sort: Sort[];
};

const sortOptions: SortOption[] = [
  {
    id: "visits-most-recent",
    label: "Visits: most recent first",
    sort: [{ [RECENT_VISITS_SORT_KEY]: OrderBy.Desc }],
  },
  {
    id: "visits-no-show",
    label: "Visits: No-show and late cancellations",
    sort: [{ [NO_SHOW_VISITS_SORT_KEY]: OrderBy.Desc }],
  },
  {
    id: "clients-new",
    label: "Clients: newest first",
    sort: [{ created: OrderBy.Desc }],
  },
  {
    id: CLIENTS_OLD_ID,
    label: "Clients: oldest first",
    sort: [{ created: OrderBy.Asc }],
  },
  {
    id: NAMES_A_Z_ID,
    label: "Names: A to Z",
    sort: [{ fullName: OrderBy.Asc }],
  },
  {
    id: "names-z-a",
    label: "Names: Z to A",
    sort: [{ fullName: OrderBy.Desc }],
  },
];

const DEFAULT_SORT_OPTION = sortOptions.find(({ id }) => id === CLIENTS_OLD_ID);
export const ALPHABETICAL_SORT_OPTION = sortOptions.find(
  ({ id }) => id === NAMES_A_Z_ID
);

const getDefaultSortOption = (defaultOptionId: string) =>
  sortOptions.find((option) => option.id === defaultOptionId) ??
  DEFAULT_SORT_OPTION;

export default function useClientsSorting(defaultSortId: string) {
  const [activeSortOption, setActiveSortOption] = useState<SortOption>(() =>
    getDefaultSortOption(defaultSortId)
  );

  const changeSortOption = (newSortOption: SortOption) => {
    Cookies.set(SORT_CLIENTS_KEY, newSortOption.id);
    setActiveSortOption(newSortOption);
  };

  return {
    sortOptions,
    activeSortOption,
    activeSortBy: activeSortOption.sort,
    changeSortOption,
  };
}
