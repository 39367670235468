import { Router, useRouter } from "next/router";
import { useEffect } from "react";
import { useIsMoxieMobile } from "./useIsMoxieMobile";
import { useMoxieMobileMessageEmitter } from "./useMoxieMobileMessageEmitter";
import { MessageListenerCallback } from "./useMoxieMobileMessageListener";

const isSignInUrl = (uri: string) => /sign-in\/factor-one/.test(uri);

/**
 * This hook is used **only** by Moxie Suite app.
 *
 * The main idea behind that is to block globally every route change and instead of navigating send proper event
 * with target url to WebView used by Moxie Suite app.
 */
export const useMoxieMobileNavigation = ({
  addMessageListener,
  removeMessageListener,
}: {
  addMessageListener: MessageListenerCallback;
  removeMessageListener: MessageListenerCallback;
}) => {
  const { isMoxieMobile } = useIsMoxieMobile();
  const { emit } = useMoxieMobileMessageEmitter();
  const { push, asPath, replace } = useRouter();

  useEffect(() => {
    if (!isMoxieMobile) {
      return;
    }

    const beforeUnloadHandler = () => {
      return ""; // Gecko + Webkit, Safari, Chrome etc.
    };

    const beforeRouteHandler = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      const [, paramString] = url.split("?");
      const params = new URLSearchParams(paramString);
      if (isSignInUrl(url)) {
        return;
      }

      if (shallow) {
        emit("updateParams", { url });
        return;
      }

      if (asPath !== url && params.get("moxie-skip") !== "true") {
        emit("navigation", url);
        Router.events.emit("routeChangeError");
        throw `Route change was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
      }
    };

    const clickListener = (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      if (element.tagName !== "A") {
        return;
      }

      const anchor = element as HTMLAnchorElement;
      e.preventDefault();
      beforeRouteHandler(anchor.href, { shallow: false });
    };

    document.body.addEventListener("click", clickListener);
    window.addEventListener("beforeunload", beforeUnloadHandler);
    Router.events.on("routeChangeStart", beforeRouteHandler);

    return () => {
      document.body.removeEventListener("click", clickListener);
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      Router.events.off("routeChangeStart", beforeRouteHandler);
    };
  }, [isMoxieMobile, asPath, emit]);

  useEffect(() => {
    const navigationMessageHandler = (payload: string) => {
      const [path, paramString] = payload.split("?");
      const params = new URLSearchParams(paramString);
      params.set("moxie-skip", "true");

      push(`${path}?${params.toString()}`).catch(console.error);
    };
    addMessageListener("navigation", navigationMessageHandler);

    return () => {
      removeMessageListener("navigation", navigationMessageHandler);
    };
  }, [push, addMessageListener, removeMessageListener]);

  useEffect(() => {
    const updateQueryParamsMessageHandler = (queryParams: string) => {
      const [pathname] = asPath.split("?");

      const url = queryParams ? `${pathname}?${queryParams}` : pathname;
      replace(url, undefined, { shallow: true }).catch(console.error);
    };
    addMessageListener("update-query-params", updateQueryParamsMessageHandler);

    return () => {
      removeMessageListener(
        "update-query-params",
        updateQueryParamsMessageHandler
      );
    };
  }, [replace, addMessageListener, removeMessageListener, asPath]);
};
