import { datadogRum, RumEvent } from "@datadog/browser-rum";
import { ENVIRONMENTS, PUBLIC_ENVIRONMENT } from "@/config";

// We may move these to environment variables in the future
// Currently, these are hardcoded because they do not change from env to env
const DATADOG_APP_ID = "4db23ee5-b274-41b2-aed3-01e8c3684db2";
const DATADOG_CLIENT_TOKEN = "pub6c1e78af147fc4d25bdb2fe5c6d6942c";

const MOXIE_MOBILE_APP_ROUTING_ERROR_TO_IGNORE =
  'Uncaught "Route change was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476."';

const handleBeforeSend = (event: RumEvent) => {
  if (
    event.type === "error" &&
    event.error.message.includes(MOXIE_MOBILE_APP_ROUTING_ERROR_TO_IGNORE)
  ) {
    return false;
  }
  return true;
};

datadogRum.init({
  applicationId: DATADOG_APP_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "moxie_suite_ui",
  env:
    PUBLIC_ENVIRONMENT === ENVIRONMENTS.PRODUCTION
      ? "prod"
      : PUBLIC_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  version: process.env.NEXT_PUBLIC_MOXIEINFRA_COMMIT_SHA,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    {
      match: process.env.NEXT_PUBLIC_GRAPHQL_URI,
      propagatorTypes: ["tracecontext"],
    },
  ],
  beforeSend: handleBeforeSend,
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
