import { IconButton, Stack, Typography } from "@mui/material";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { CheckSmallIcon, CrossSmallIcon } from "@/components/common/icons";
import IconCircleContainer from "@/components/common/icons/containers/iconCircleContainer";
import {
  ERROR_PRIMARY,
  GREY,
  SUCCESS_PRIMARY,
  TEXT_PRIMARY_WHITE,
  WHITE,
} from "@/config/mui/colorPalette";

export default function MainToaster() {
  const successIcon = (
    <IconCircleContainer
      color={SUCCESS_PRIMARY}
      size="24px"
      icon={<CheckSmallIcon color={WHITE} size={20} />}
    />
  );

  const errorIcon = (
    <IconCircleContainer
      color={ERROR_PRIMARY}
      size="24px"
      icon={<CrossSmallIcon color={WHITE} size={20} />}
    />
  );

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 3200,
        style: {
          padding: "8px 12px",
          borderRadius: "12px",
          color: TEXT_PRIMARY_WHITE,
          backgroundColor: GREY[80],
          boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.16)",
        },
        success: {
          icon: successIcon,
        },
        error: {
          icon: errorIcon,
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-center">
          {({ icon, message }) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap="12px"
            >
              {icon}
              <Typography
                variant="paragraphSmall"
                component="div"
                sx={{
                  "& div": {
                    m: 0,
                  },
                }}
              >
                {message}
              </Typography>
              {t.type !== "loading" && (
                <IconButton
                  sx={{
                    p: 0,
                    backgroundColor: GREY[70],
                    "&:hover": {
                      backgroundColor: GREY[60],
                    },
                  }}
                  onClick={(e) => {
                    // When dismiss button is inside a toast, it does not record the mouseleave event which is required
                    // https://github.com/timolins/react-hot-toast/issues/128#issuecomment-1958316826
                    const mouseLeaveEvent = new MouseEvent("mouseout", {
                      bubbles: true,
                      cancelable: true,
                    });
                    e.currentTarget.dispatchEvent(mouseLeaveEvent);

                    toast.dismiss(t.id);
                    // according to docs toast should be removed after 1s BUT IT DOESN't WORK with custom toasts
                    // doing it manually here to make sure it doesn't stay in the DOM
                    setTimeout(() => toast.remove(t.id), 1000);
                  }}
                >
                  <CrossSmallIcon color={WHITE} size={20} />
                </IconButton>
              )}
            </Stack>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
