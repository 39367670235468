import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { GREY, PRIMARY } from "@/config/mui/colorPalette";
import useDebouncedScrollTrigger from "@/hooks/misc/useDebouncedScrollTrigger";
import { useMoxieMobileMessageEmitter } from "@/hooks/moxieMobile/useMoxieMobileMessageEmitter";
import { ArrowLeftIcon } from "../icons";

type Props = {
  goBackUrl?: string;
  goBackOnClick?: () => void;
  goBackStrategy?: "history" | "url" | "onClick";
  title?: ReactNode;
  action?: ReactNode;
  backOptionColor?: string;
  icon?: ReactNode;
  iconBackground?: string;
  disableBorder?: boolean;
  centerTitle?: boolean;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
};

function PageHeader({
  goBackUrl,
  goBackStrategy,
  title,
  action,
  backOptionColor,
  goBackOnClick,
  icon,
  iconBackground,
  disableBorder = false,
  centerTitle = true,
  sx = {},
  iconSx = {},
}: Props) {
  const router = useRouter();
  const trigger = useDebouncedScrollTrigger();
  const { emit } = useMoxieMobileMessageEmitter();

  // not using Moxie media queries since this should match PageContainer maxWidth
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box
      sx={{
        position: "sticky",
        transition: trigger // same as MUI Slide transitions
          ? "top 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;"
          : "top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;",
        top: 0,
        borderBottom: !disableBorder && `1px solid ${GREY[30]}`, // Used on photo editor
        zIndex: 900,
        background: "inherit",
        p: 1,
        mt: -1,
        display: "flex",
        alignItems: "center",
        mb: 3,
        mx: isXL ? 0 : -2,
        minHeight: "56px",
        ...sx,
      }}
    >
      {goBackStrategy && (
        <IconButton
          color="primary"
          aria-label="Go back"
          sx={{ position: "absolute", ml: isXL ? -2 : 0 }}
          onClick={() => {
            if (goBackStrategy === "history") {
              emit("goBack");
              router.back();
            } else if (goBackStrategy === "url") {
              router.push(goBackUrl);
            } else {
              goBackOnClick();
            }
          }}
        >
          <ArrowLeftIcon
            color={backOptionColor ?? theme.palette.primary.main}
          />
        </IconButton>
      )}
      {icon ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ mx: centerTitle ? "auto" : 0 }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              bgcolor: iconBackground || "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...iconSx,
            }}
          >
            {icon}
          </Box>
          <Typography variant="h2" color="text.primary" align="center">
            {title} &zwnj;
          </Typography>
        </Stack>
      ) : (
        <>
          {typeof title === "string" ? (
            <Typography
              variant="h2"
              color="text.primary"
              align="center"
              sx={{ flexGrow: 1 }}
            >
              {title} &zwnj;
            </Typography>
          ) : (
            title
          )}
        </>
      )}

      {action && (
        <Box sx={{ position: "absolute", right: isXL ? 0 : 16 }}>{action}</Box>
      )}
    </Box>
  );
}

export default PageHeader;

interface CommonPageHeaderButtonProps extends ButtonProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

interface OnClickPageHeaderButtonProps extends CommonPageHeaderButtonProps {
  link: string;
  onClick?: never;
}

interface LinkPageHeaderButtonProps extends CommonPageHeaderButtonProps {
  onClick: () => void;
  link?: never;
}

type PageHeaderButtonProps =
  | OnClickPageHeaderButtonProps
  | LinkPageHeaderButtonProps;

export function PageHeaderButton({
  onClick,
  children,
  sx,
  link,
  ...props
}: PageHeaderButtonProps) {
  return (
    <Button
      size="small"
      onClick={onClick}
      href={link}
      sx={{
        color: PRIMARY,
        cursor: "pointer",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function PageHeaderTitleLoader({
  width = "200px",
  height = 34,
}: {
  width?: string;
  height?: number;
}) {
  return (
    <Box sx={{ mx: "auto", width }}>
      <Skeleton height={height} />
    </Box>
  );
}
