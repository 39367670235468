import DateRangePicker, {
  DateRange,
} from "@/components/common/datetimePicker/dateRangePicker";
import RadioList, { RadioItem } from "@/components/common/list/radioList";
import FilterDialog, {
  FilterDialogBaseProps,
} from "@/components/common/modals/filterDialog";

export enum DatePeriods {
  NEXT_30_DAYS = "next_30_days",
  CURRENT_WEEK = "current_week",
  CURRENT_MONTH = "current_month",
  LAST_WEEK = "last_week",
  LAST_MONTH = "last_month",
  LAST_3_DAYS = "last_3_days",
  LAST_7_DAYS = "last_7_days",
  LAST_30_DAYS = "last_30_days",
}

export const DEFAULT_PERIOD_OPTIONS = [
  DatePeriods.NEXT_30_DAYS,
  DatePeriods.CURRENT_MONTH,
  DatePeriods.LAST_MONTH,
  DatePeriods.CURRENT_WEEK,
  DatePeriods.LAST_WEEK,
];

export const DATE_PERIOD_LABELS = {
  [DatePeriods.NEXT_30_DAYS]: "Next 30 days",
  [DatePeriods.CURRENT_WEEK]: "Current week",
  [DatePeriods.CURRENT_MONTH]: "Current month",
  [DatePeriods.LAST_WEEK]: "Last week",
  [DatePeriods.LAST_MONTH]: "Last month",
  [DatePeriods.LAST_3_DAYS]: "Last 3 days",
  [DatePeriods.LAST_7_DAYS]: "Last 7 days",
  [DatePeriods.LAST_30_DAYS]: "Last 30 days",
};

type DateDialogProps = FilterDialogBaseProps & {
  filterTitle: string;
  periodOptions: DatePeriods[];
  withRange?: boolean;
  selectedPeriod: DatePeriods;
  selectedRange: DateRange;
  onPeriodChange: (period: DatePeriods) => void;
  onRangeChange: (range: DateRange) => void;
};

function DateDialog({
  filterTitle,
  periodOptions,
  selectedPeriod,
  selectedRange,
  withRange = true,
  onPeriodChange,
  onRangeChange,
  ...dialogProps
}: DateDialogProps) {
  const items: RadioItem[] = periodOptions.map((period) => ({
    id: period,
    label: DATE_PERIOD_LABELS[period],
    onChange: () => onPeriodChange(period),
    isChecked: selectedPeriod === period,
  }));

  return (
    <FilterDialog
      {...dialogProps}
      title={filterTitle}
      content={
        <>
          {withRange && (
            <DateRangePicker
              selectedRange={selectedRange}
              onRangeChange={onRangeChange}
            />
          )}
          <RadioList items={items} />
        </>
      }
    />
  );
}

export default DateDialog;
