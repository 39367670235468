import { PRIMARY } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
  set?: "outline" | "duotone";
  color?: string;
};

export default function CamIconSvg({
  size = "24",
  set = "outline",
  color = PRIMARY,
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M3.5 6.5C3.5 6.22386 3.72386 6 4 6H15C15.2762 6 15.5 6.22386 15.5 6.5V17.5C15.5 17.7762 15.2762 18 15 18H4C3.72386 18 3.5 17.7762 3.5 17.5V6.5Z"
        fill={set === "duotone" ? color : undefined}
        stroke={color}
      />
      <path
        d="M16 9L21 7V17L16 15M15 5.5H4C3.44772 5.5 3 5.94772 3 6.5V17.5C3 18.0523 3.44772 18.5 4 18.5H15C15.5523 18.5 16 18.0523 16 17.5V6.5C16 5.94772 15.5523 5.5 15 5.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
