import { QueryHookOptions } from "@apollo/client";
import { useMemo } from "react";
import {
  ServiceMenuItemDocumentsTableQuery,
  ServiceMenuItemDocumentsTableQueryVariables,
  useServiceMenuItemDocumentsTableQuery,
} from "@/graphql/queries/complianceHub/serviceMenuItemDocumentsTable.graphql.types";
import { ServiceDocumentFormType } from "@/types";
import { isPartOfAString } from "@/utils";

export default function useServiceDocumentsTableData(
  queryOptions?: QueryHookOptions<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >
) {
  const { data, loading, ...rest } =
    useServiceMenuItemDocumentsTableQuery(queryOptions);

  const shapedData = useMemo(
    () =>
      data?.medspaServiceMenuItem.map((serviceMenuItem) => ({
        ...serviceMenuItem,
        [ServiceDocumentFormType.INTAKE]: {
          count: getIntakeFormsCount(serviceMenuItem.medspaServiceOfferings),
          required: serviceMenuItem.requiresIntakeForms,
        },
        [ServiceDocumentFormType.CONSENT]: {
          count:
            serviceMenuItem.consentFormServiceMenuItemsAggregate?.aggregate
              ?.count,
          required: serviceMenuItem.requiresConsentForms,
        },
        [ServiceDocumentFormType.POSTCARE]: {
          count: serviceMenuItem.postCareFormAggregate?.aggregate?.count,
          required: serviceMenuItem.requiresPostCareForms,
        },
        [ServiceDocumentFormType.PRECARE]: {
          count: serviceMenuItem.preCareFormAggregate?.aggregate?.count,
          required: serviceMenuItem.requiresPreCareForms,
        },
        careFormsShouldNotBeRequired:
          shouldCareFormsBeOptional(serviceMenuItem),
      })),
    [data]
  );

  const issueCount = shapedData?.reduce(
    (outerAcc, processedData) =>
      outerAcc +
      Object.values(ServiceDocumentFormType).reduce(
        (innerAcc, formType) =>
          innerAcc +
          Number(
            processedData[formType].required &&
              processedData[formType].count === 0
          ),
        0
      ),
    0
  );

  return { data: shapedData, issues: issueCount, isLoading: loading, ...rest };
}

function getIntakeFormsCount(
  serviceOfferings: ServiceMenuItemDocumentsTableQuery["medspaServiceMenuItem"][number]["medspaServiceOfferings"]
) {
  const intakeForms = new Set<string>();

  serviceOfferings.forEach((serviceOffering) => {
    serviceOffering.serviceOffering.forms.forEach((form) => {
      intakeForms.add(form.formId);
    });
  });

  return intakeForms.size;
}

function shouldCareFormsBeOptional(
  serviceMenuItem: ServiceMenuItemDocumentsTableQuery["medspaServiceMenuItem"][number]
) {
  // verify if a service menu item is connected only to a consultation or follow up service offering
  return serviceMenuItem.medspaServiceOfferings
    .map((serviceOffering) =>
      ["consultation", "follow up"].some((serviceName) =>
        isPartOfAString(
          serviceName,
          serviceOffering.serviceOffering.serviceType.name
        )
      )
    )
    .every(Boolean);
}
