import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ERROR_PRIMARY, ERROR } from "@/config/mui/colorPalette";

export const NotificationsDot = ({ count }: { count: number }) => {
  return (
    <Box
      sx={{
        px: 1,
        borderRadius: "50%",
        minWidth: "24px",
        minHeight: "24px",
        bgcolor: ERROR[10],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="labelTiny" color={ERROR_PRIMARY}>
        {count}
      </Typography>
    </Box>
  );
};
