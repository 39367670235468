import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { differenceInMinutes, format } from "date-fns";
import React, { forwardRef } from "react";
import { CalendarClock } from "react-swm-icon-pack";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { GREY, TEXT_TERTIARY, VIOLET } from "@/config/mui/colorPalette";
import { formattedTime } from "@/utils/date";

type Props = {
  onClick?: VoidFunction;
  durationText?: string;
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
};

const TimeSelectorButton: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (
  {
    onClick = () => {},
    durationText = "",
    disabled = false,
    startDate,
    endDate,
  },
  ref
) => {
  const dateTimeText =
    startDate && endDate
      ? `${format(startDate, DATE_FORMATS.DATE_PICKER)}, ${format(startDate, TIME_FORMATS.TILE)} - ${format(endDate, TIME_FORMATS.TILE)}`
      : "Date & time not selected yet";

  const duration =
    startDate && endDate ? differenceInMinutes(endDate, startDate) : 0;

  const formattedDuration =
    duration > 0 ? `${formattedTime(duration)}${durationText}` : undefined;

  return (
    <ListItemButton
      disabled={disabled}
      ref={ref}
      onClick={onClick}
      sx={{
        backgroundColor: GREY[10],
        borderRadius: 2,
        gap: 2,
      }}
    >
      <Box sx={{ alignSelf: "start" }}>
        <CalendarClock color={TEXT_TERTIARY} size={20} />
      </Box>
      <ListItemText
        sx={{ m: 0, mt: -0.5 }}
        primary={dateTimeText}
        primaryTypographyProps={{
          variant: "labelSmall",
          color: disabled ? "text.disabled" : "text.primary",
        }}
        secondary={formattedDuration}
        secondaryTypographyProps={{
          variant: "labelTiny",
          color: "text.disabled",
        }}
      />
      <Typography
        sx={{ alignSelf: "start" }}
        variant="labelSmall"
        color={disabled ? TEXT_TERTIARY : VIOLET[90]}
      >
        {startDate && endDate ? "Edit" : "Select"}
      </Typography>
    </ListItemButton>
  );
};

export default forwardRef(TimeSelectorButton);
